.alert {
    position: fixed;
    z-index: 1;
    /* right: 0; */
    /* left: 0; */
    margin-right: auto;
    margin-left: auto;
}

.alert-soft-warning {
    background-color: #996d00;
}