.dk-switch:focus,
.dk-switch.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 1px #12263f,0 0 0 .15rem rgba(44,123,229,.25);
    box-shadow: 0 0 0 1px #12263f,0 0 0 .15rem rgba(44,123,229,.25);
}

.dk-switch {
    font-size: 14px;
    line-height: 1.5;
    color: #6c757d;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    min-width: 44px;
    height: 22px;
    line-height: 20px;
    vertical-align: middle;
    border-radius: 100px;
    border: 1px solid transparent;
    background-color: #244166;
    -webkit-transition: background-color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    cursor: pointer;
}

.dk-switch-checked {
    color: #fff;
    background-color: #2c7be5;
}

.dk-switch-checked .dk-switch-inner {
    margin-left: 6px;
    margin-right: 20px;
}

.dk-switch-inner {
    display: block;
    margin-left: 20px;
    margin-right: 6px;
    padding-left: 4px;
    padding-right: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
}

.dk-switch::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    left: 1px;
    top: 1px;
    border-radius: 50%;
    background-color: #152e4d;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    cursor: pointer;
}

.dk-switch-checked::after {
    left: 100%;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    margin-left: -1px;
    background-color: #fff;
}