.select-list {
    background-color: #152e4d;
}

.select-item.selected {
    background-color: #2c517d;
}

.select-item:hover {
    background-color: #1e3a5c;
}