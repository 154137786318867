/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@font-face {
    font-family: Cerebri Sans;
    font-weight: 400;
    font-style: normal;
    src: url(assets/fonts/cerebrisans/cerebrisans-regular.eot);
    src: url(assets/fonts/cerebrisans/cerebrisans-regulard41d.eot?#iefix) format("embedded-opentype"), url(assets/fonts/cerebrisans/cerebrisans-regular.woff) format("woff"), url(/assets/fonts/cerebrisans/cerebrisans-regular.ttf) format("truetype")
}

@font-face {
    font-family: Cerebri Sans;
    font-weight: 500;
    font-style: normal;
    src: url(assets/fonts/cerebrisans/cerebrisans-medium.eot);
    src: url(assets/fonts/cerebrisans/cerebrisans-mediumd41d.eot?#iefix) format("embedded-opentype"), url(assets/fonts/cerebrisans/cerebrisans-medium.woff) format("woff"), url(/assets/fonts/cerebrisans/cerebrisans-medium.ttf) format("truetype")
}

@font-face {
    font-family: Cerebri Sans;
    font-weight: 600;
    font-style: normal;
    src: url(assets/fonts/cerebrisans/cerebrisans-semibold.eot);
    src: url(assets/fonts/cerebrisans/cerebrisans-semiboldd41d.eot?#iefix) format("embedded-opentype"), url(assets/fonts/cerebrisans/cerebrisans-semibold.woff) format("woff"), url(/assets/fonts/cerebrisans/cerebrisans-semibold.ttf) format("truetype")
}

*,
::after,
::before {
    box-sizing: border-box;
}

body {
    font-family: Cerebri Sans, sans-serif;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    text-align: left;
    color: #fff;
    background-color: #12263f
}

a {
    text-decoration: none;
    color: #2c7be5;
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

a:hover {
    text-decoration: none;
    color: #1657af
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    text-decoration: none;
    color: inherit
}

a:not([href]):not([tabindex]):focus {
    outline: 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 1.125rem
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 1.125rem;
    color: inherit
}

.h1,
h1 {
    font-size: 1.625rem
}

.h2,
h2 {
    font-size: 1.25rem
}

.h3,
h3 {
    font-size: 1.0625rem
}

.h4,
h4 {
    font-size: .9375rem
}

.h5,
h5 {
    font-size: .8125rem
}

.h6,
h6 {
    font-size: .625rem
}

.h1,
h1 {
    font-size: 1.5rem;
    margin-bottom: 1.125rem
}

@media (min-width:768px) {
    .h1,
    h1 {
        font-size: 1.625rem
    }
}

.h2,
h2 {
    margin-bottom: 1.125rem
}

.h3,
h3 {
    margin-bottom: .84375rem
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
    margin-bottom: .5625rem
}

h1>a,
h2>a,
h3>a,
h4>a,
h5>a,
h6>a {
    color: inherit
}

.display-1,
.display-2,
.display-3,
.display-4,
h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: -.02em
}

h6.text-uppercase {
    letter-spacing: .08em
}