.msg-icon-error {
    color: red;
}

.msg-icon-warning {
    color: yellow;
}

.msg-icon-info {
    color: white;
}

.history-table-success {
    color: green;
}

.history-table-fail {
    color: red;
}