.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

@media (min-width:576px) {
    .flex-sm-row {
        flex-direction: row !important
    }
    .flex-sm-column {
        flex-direction: column !important
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }
    .flex-sm-wrap {
        flex-wrap: wrap !important
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }
    .flex-sm-fill {
        flex: 1 1 auto !important
    }
    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }
    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }
    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }
    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }
    .justify-content-sm-start {
        justify-content: flex-start !important
    }
    .justify-content-sm-end {
        justify-content: flex-end !important
    }
    .justify-content-sm-center {
        justify-content: center !important
    }
    .justify-content-sm-between {
        justify-content: space-between !important
    }
    .justify-content-sm-around {
        justify-content: space-around !important
    }
    .align-items-sm-start {
        align-items: flex-start !important
    }
    .align-items-sm-end {
        align-items: flex-end !important
    }
    .align-items-sm-center {
        align-items: center !important
    }
    .align-items-sm-baseline {
        align-items: baseline !important
    }
    .align-items-sm-stretch {
        align-items: stretch !important
    }
    .align-content-sm-start {
        align-content: flex-start !important
    }
    .align-content-sm-end {
        align-content: flex-end !important
    }
    .align-content-sm-center {
        align-content: center !important
    }
    .align-content-sm-between {
        align-content: space-between !important
    }
    .align-content-sm-around {
        align-content: space-around !important
    }
    .align-content-sm-stretch {
        align-content: stretch !important
    }
    .align-self-sm-auto {
        align-self: auto !important
    }
    .align-self-sm-start {
        align-self: flex-start !important
    }
    .align-self-sm-end {
        align-self: flex-end !important
    }
    .align-self-sm-center {
        align-self: center !important
    }
    .align-self-sm-baseline {
        align-self: baseline !important
    }
    .align-self-sm-stretch {
        align-self: stretch !important
    }
}

@media (min-width:768px) {
    .flex-md-row {
        flex-direction: row !important
    }
    .flex-md-column {
        flex-direction: column !important
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }
    .flex-md-wrap {
        flex-wrap: wrap !important
    }
    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }
    .flex-md-fill {
        flex: 1 1 auto !important
    }
    .flex-md-grow-0 {
        flex-grow: 0 !important
    }
    .flex-md-grow-1 {
        flex-grow: 1 !important
    }
    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }
    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }
    .justify-content-md-start {
        justify-content: flex-start !important
    }
    .justify-content-md-end {
        justify-content: flex-end !important
    }
    .justify-content-md-center {
        justify-content: center !important
    }
    .justify-content-md-between {
        justify-content: space-between !important
    }
    .justify-content-md-around {
        justify-content: space-around !important
    }
    .align-items-md-start {
        align-items: flex-start !important
    }
    .align-items-md-end {
        align-items: flex-end !important
    }
    .align-items-md-center {
        align-items: center !important
    }
    .align-items-md-baseline {
        align-items: baseline !important
    }
    .align-items-md-stretch {
        align-items: stretch !important
    }
    .align-content-md-start {
        align-content: flex-start !important
    }
    .align-content-md-end {
        align-content: flex-end !important
    }
    .align-content-md-center {
        align-content: center !important
    }
    .align-content-md-between {
        align-content: space-between !important
    }
    .align-content-md-around {
        align-content: space-around !important
    }
    .align-content-md-stretch {
        align-content: stretch !important
    }
    .align-self-md-auto {
        align-self: auto !important
    }
    .align-self-md-start {
        align-self: flex-start !important
    }
    .align-self-md-end {
        align-self: flex-end !important
    }
    .align-self-md-center {
        align-self: center !important
    }
    .align-self-md-baseline {
        align-self: baseline !important
    }
    .align-self-md-stretch {
        align-self: stretch !important
    }
}

@media (min-width:992px) {
    .flex-lg-row {
        flex-direction: row !important
    }
    .flex-lg-column {
        flex-direction: column !important
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }
    .flex-lg-wrap {
        flex-wrap: wrap !important
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }
    .flex-lg-fill {
        flex: 1 1 auto !important
    }
    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }
    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }
    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }
    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }
    .justify-content-lg-start {
        justify-content: flex-start !important
    }
    .justify-content-lg-end {
        justify-content: flex-end !important
    }
    .justify-content-lg-center {
        justify-content: center !important
    }
    .justify-content-lg-between {
        justify-content: space-between !important
    }
    .justify-content-lg-around {
        justify-content: space-around !important
    }
    .align-items-lg-start {
        align-items: flex-start !important
    }
    .align-items-lg-end {
        align-items: flex-end !important
    }
    .align-items-lg-center {
        align-items: center !important
    }
    .align-items-lg-baseline {
        align-items: baseline !important
    }
    .align-items-lg-stretch {
        align-items: stretch !important
    }
    .align-content-lg-start {
        align-content: flex-start !important
    }
    .align-content-lg-end {
        align-content: flex-end !important
    }
    .align-content-lg-center {
        align-content: center !important
    }
    .align-content-lg-between {
        align-content: space-between !important
    }
    .align-content-lg-around {
        align-content: space-around !important
    }
    .align-content-lg-stretch {
        align-content: stretch !important
    }
    .align-self-lg-auto {
        align-self: auto !important
    }
    .align-self-lg-start {
        align-self: flex-start !important
    }
    .align-self-lg-end {
        align-self: flex-end !important
    }
    .align-self-lg-center {
        align-self: center !important
    }
    .align-self-lg-baseline {
        align-self: baseline !important
    }
    .align-self-lg-stretch {
        align-self: stretch !important
    }
}

@media (min-width:1200px) {
    .flex-xl-row {
        flex-direction: row !important
    }
    .flex-xl-column {
        flex-direction: column !important
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }
    .flex-xl-wrap {
        flex-wrap: wrap !important
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }
    .flex-xl-fill {
        flex: 1 1 auto !important
    }
    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }
    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }
    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }
    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }
    .justify-content-xl-start {
        justify-content: flex-start !important
    }
    .justify-content-xl-end {
        justify-content: flex-end !important
    }
    .justify-content-xl-center {
        justify-content: center !important
    }
    .justify-content-xl-between {
        justify-content: space-between !important
    }
    .justify-content-xl-around {
        justify-content: space-around !important
    }
    .align-items-xl-start {
        align-items: flex-start !important
    }
    .align-items-xl-end {
        align-items: flex-end !important
    }
    .align-items-xl-center {
        align-items: center !important
    }
    .align-items-xl-baseline {
        align-items: baseline !important
    }
    .align-items-xl-stretch {
        align-items: stretch !important
    }
    .align-content-xl-start {
        align-content: flex-start !important
    }
    .align-content-xl-end {
        align-content: flex-end !important
    }
    .align-content-xl-center {
        align-content: center !important
    }
    .align-content-xl-between {
        align-content: space-between !important
    }
    .align-content-xl-around {
        align-content: space-around !important
    }
    .align-content-xl-stretch {
        align-content: stretch !important
    }
    .align-self-xl-auto {
        align-self: auto !important
    }
    .align-self-xl-start {
        align-self: flex-start !important
    }
    .align-self-xl-end {
        align-self: flex-end !important
    }
    .align-self-xl-center {
        align-self: center !important
    }
    .align-self-xl-baseline {
        align-self: baseline !important
    }
    .align-self-xl-stretch {
        align-self: stretch !important
    }
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

@media (min-width:576px) {
    .float-sm-left {
        float: left !important
    }
    .float-sm-right {
        float: right !important
    }
    .float-sm-none {
        float: none !important
    }
}

@media (min-width:768px) {
    .float-md-left {
        float: left !important
    }
    .float-md-right {
        float: right !important
    }
    .float-md-none {
        float: none !important
    }
}

@media (min-width:992px) {
    .float-lg-left {
        float: left !important
    }
    .float-lg-right {
        float: right !important
    }
    .float-lg-none {
        float: none !important
    }
}

@media (min-width:1200px) {
    .float-xl-left {
        float: left !important
    }
    .float-xl-right {
        float: right !important
    }
    .float-xl-none {
        float: none !important
    }
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}

.fixed-top {
    top: 0
}

.fixed-bottom,
.fixed-top {
    position: fixed;
    z-index: 1030;
    right: 0;
    left: 0
}

.fixed-bottom {
    bottom: 0
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        z-index: 1020;
        top: 0
    }
}

.sr-only {
    position: absolute;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    width: 1px;
    height: 1px;
    padding: 0;
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    overflow: visible;
    clip: auto;
    width: auto;
    height: auto;
    white-space: normal
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(18, 38, 63, .075) !important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(18, 38, 63, .15) !important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(18, 38, 63, .175) !important
}

.shadow-none {
    box-shadow: none !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.w-15 {
    width: 15% !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.h-15 {
    height: 15% !important
}

.mw-100 {
    max-width: 100% !important
}

.mh-100 {
    max-height: 100% !important
}

.m-0 {
    margin: 0 !important
}

.mt-0,
.my-0 {
    margin-top: 0 !important
}

.mr-0,
.mx-0 {
    margin-right: 0 !important
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
    margin-left: 0 !important
}

.m-1 {
    margin: .1875rem !important
}

.mt-1,
.my-1 {
    margin-top: .1875rem !important
}

.mr-1,
.mx-1 {
    margin-right: .1875rem !important
}

.mb-1,
.my-1 {
    margin-bottom: .1875rem !important
}

.ml-1,
.mx-1 {
    margin-left: .1875rem !important
}

.m-2 {
    margin: .375rem !important
}

.mt-2,
.my-2 {
    margin-top: .375rem !important
}

.mr-2,
.mx-2 {
    margin-right: .375rem !important
}

.mb-2,
.my-2 {
    margin-bottom: .375rem !important
}

.ml-2,
.mx-2 {
    margin-left: .375rem !important
}

.m-3 {
    margin: .75rem !important
}

.mt-3,
.my-3 {
    margin-top: .75rem !important
}

.mr-3,
.mx-3 {
    margin-right: .75rem !important
}

.mb-3,
.my-3 {
    margin-bottom: .75rem !important
}

.ml-3,
.mx-3 {
    margin-left: .75rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important
}

.m-5 {
    margin: 2.25rem !important
}

.mt-5,
.my-5 {
    margin-top: 2.25rem !important
}

.mr-5,
.mx-5 {
    margin-right: 2.25rem !important
}

.mb-5,
.my-5 {
    margin-bottom: 2.25rem !important
}

.ml-5,
.mx-5 {
    margin-left: 2.25rem !important
}

.m--8 {
    margin: -13.5rem !important
}

.mt--8,
.my--8 {
    margin-top: -13.5rem !important
}

.mr--8,
.mx--8 {
    margin-right: -13.5rem !important
}

.mb--8,
.my--8 {
    margin-bottom: -13.5rem !important
}

.ml--8,
.mx--8 {
    margin-left: -13.5rem !important
}

.m--7 {
    margin: -6.75rem !important
}

.mt--7,
.my--7 {
    margin-top: -6.75rem !important
}

.mr--7,
.mx--7 {
    margin-right: -6.75rem !important
}

.mb--7,
.my--7 {
    margin-bottom: -6.75rem !important
}

.ml--7,
.mx--7 {
    margin-left: -6.75rem !important
}

.m--6 {
    margin: -4.5rem !important
}

.mt--6,
.my--6 {
    margin-top: -4.5rem !important
}

.mr--6,
.mx--6 {
    margin-right: -4.5rem !important
}

.mb--6,
.my--6 {
    margin-bottom: -4.5rem !important
}

.ml--6,
.mx--6 {
    margin-left: -4.5rem !important
}

.m--5 {
    margin: -2.25rem !important
}

.mt--5,
.my--5 {
    margin-top: -2.25rem !important
}

.mr--5,
.mx--5 {
    margin-right: -2.25rem !important
}

.mb--5,
.my--5 {
    margin-bottom: -2.25rem !important
}

.ml--5,
.mx--5 {
    margin-left: -2.25rem !important
}

.m--4 {
    margin: -1.5rem !important
}

.mt--4,
.my--4 {
    margin-top: -1.5rem !important
}

.mr--4,
.mx--4 {
    margin-right: -1.5rem !important
}

.mb--4,
.my--4 {
    margin-bottom: -1.5rem !important
}

.ml--4,
.mx--4 {
    margin-left: -1.5rem !important
}

.m--3 {
    margin: -.75rem !important
}

.mt--3,
.my--3 {
    margin-top: -.75rem !important
}

.mr--3,
.mx--3 {
    margin-right: -.75rem !important
}

.mb--3,
.my--3 {
    margin-bottom: -.75rem !important
}

.ml--3,
.mx--3 {
    margin-left: -.75rem !important
}

.m--2 {
    margin: -.375rem !important
}

.mt--2,
.my--2 {
    margin-top: -.375rem !important
}

.mr--2,
.mx--2 {
    margin-right: -.375rem !important
}

.mb--2,
.my--2 {
    margin-bottom: -.375rem !important
}

.ml--2,
.mx--2 {
    margin-left: -.375rem !important
}

.m--1 {
    margin: -.1875rem !important
}

.mt--1,
.my--1 {
    margin-top: -.1875rem !important
}

.mr--1,
.mx--1 {
    margin-right: -.1875rem !important
}

.mb--1,
.my--1 {
    margin-bottom: -.1875rem !important
}

.ml--1,
.mx--1 {
    margin-left: -.1875rem !important
}

.m-6 {
    margin: 4.5rem !important
}

.mt-6,
.my-6 {
    margin-top: 4.5rem !important
}

.mr-6,
.mx-6 {
    margin-right: 4.5rem !important
}

.mb-6,
.my-6 {
    margin-bottom: 4.5rem !important
}

.ml-6,
.mx-6 {
    margin-left: 4.5rem !important
}

.m-7 {
    margin: 6.75rem !important
}

.mt-7,
.my-7 {
    margin-top: 6.75rem !important
}

.mr-7,
.mx-7 {
    margin-right: 6.75rem !important
}

.mb-7,
.my-7 {
    margin-bottom: 6.75rem !important
}

.ml-7,
.mx-7 {
    margin-left: 6.75rem !important
}

.m-8 {
    margin: 13.5rem !important
}

.mt-8,
.my-8 {
    margin-top: 13.5rem !important
}

.mr-8,
.mx-8 {
    margin-right: 13.5rem !important
}

.mb-8,
.my-8 {
    margin-bottom: 13.5rem !important
}

.ml-8,
.mx-8 {
    margin-left: 13.5rem !important
}

.p-0 {
    padding: 0 !important
}

.pt-0,
.py-0 {
    padding-top: 0 !important
}

.pr-0,
.px-0 {
    padding-right: 0 !important
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important
}

.pl-0,
.px-0 {
    padding-left: 0 !important
}

.p-1 {
    padding: .1875rem !important
}

.pt-1,
.py-1 {
    padding-top: .1875rem !important
}

.pr-1,
.px-1 {
    padding-right: .1875rem !important
}

.pb-1,
.py-1 {
    padding-bottom: .1875rem !important
}

.pl-1,
.px-1 {
    padding-left: .1875rem !important
}

.p-2 {
    padding: .375rem !important
}

.pt-2,
.py-2 {
    padding-top: .375rem !important
}

.pr-2,
.px-2 {
    padding-right: .375rem !important
}

.pb-2,
.py-2 {
    padding-bottom: .375rem !important
}

.pl-2,
.px-2 {
    padding-left: .375rem !important
}

.p-3 {
    padding: .75rem !important
}

.pt-3,
.py-3 {
    padding-top: .75rem !important
}

.pr-3,
.px-3 {
    padding-right: .75rem !important
}

.pb-3,
.py-3 {
    padding-bottom: .75rem !important
}

.pl-3,
.px-3 {
    padding-left: .75rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important
}

.p-5 {
    padding: 2.25rem !important
}

.pt-5,
.py-5 {
    padding-top: 2.25rem !important
}

.pr-5,
.px-5 {
    padding-right: 2.25rem !important
}

.pb-5,
.py-5 {
    padding-bottom: 2.25rem !important
}

.pl-5,
.px-5 {
    padding-left: 2.25rem !important
}

.p--8 {
    padding: -13.5rem !important
}

.pt--8,
.py--8 {
    padding-top: -13.5rem !important
}

.pr--8,
.px--8 {
    padding-right: -13.5rem !important
}

.pb--8,
.py--8 {
    padding-bottom: -13.5rem !important
}

.pl--8,
.px--8 {
    padding-left: -13.5rem !important
}

.p--7 {
    padding: -6.75rem !important
}

.pt--7,
.py--7 {
    padding-top: -6.75rem !important
}

.pr--7,
.px--7 {
    padding-right: -6.75rem !important
}

.pb--7,
.py--7 {
    padding-bottom: -6.75rem !important
}

.pl--7,
.px--7 {
    padding-left: -6.75rem !important
}

.p--6 {
    padding: -4.5rem !important
}

.pt--6,
.py--6 {
    padding-top: -4.5rem !important
}

.pr--6,
.px--6 {
    padding-right: -4.5rem !important
}

.pb--6,
.py--6 {
    padding-bottom: -4.5rem !important
}

.pl--6,
.px--6 {
    padding-left: -4.5rem !important
}

.p--5 {
    padding: -2.25rem !important
}

.pt--5,
.py--5 {
    padding-top: -2.25rem !important
}

.pr--5,
.px--5 {
    padding-right: -2.25rem !important
}

.pb--5,
.py--5 {
    padding-bottom: -2.25rem !important
}

.pl--5,
.px--5 {
    padding-left: -2.25rem !important
}

.p--4 {
    padding: -1.5rem !important
}

.pt--4,
.py--4 {
    padding-top: -1.5rem !important
}

.pr--4,
.px--4 {
    padding-right: -1.5rem !important
}

.pb--4,
.py--4 {
    padding-bottom: -1.5rem !important
}

.pl--4,
.px--4 {
    padding-left: -1.5rem !important
}

.p--3 {
    padding: -.75rem !important
}

.pt--3,
.py--3 {
    padding-top: -.75rem !important
}

.pr--3,
.px--3 {
    padding-right: -.75rem !important
}

.pb--3,
.py--3 {
    padding-bottom: -.75rem !important
}

.pl--3,
.px--3 {
    padding-left: -.75rem !important
}

.p--2 {
    padding: -.375rem !important
}

.pt--2,
.py--2 {
    padding-top: -.375rem !important
}

.pr--2,
.px--2 {
    padding-right: -.375rem !important
}

.pb--2,
.py--2 {
    padding-bottom: -.375rem !important
}

.pl--2,
.px--2 {
    padding-left: -.375rem !important
}

.p--1 {
    padding: -.1875rem !important
}

.pt--1,
.py--1 {
    padding-top: -.1875rem !important
}

.pr--1,
.px--1 {
    padding-right: -.1875rem !important
}

.pb--1,
.py--1 {
    padding-bottom: -.1875rem !important
}

.pl--1,
.px--1 {
    padding-left: -.1875rem !important
}

.p-6 {
    padding: 4.5rem !important
}

.pt-6,
.py-6 {
    padding-top: 4.5rem !important
}

.pr-6,
.px-6 {
    padding-right: 4.5rem !important
}

.pb-6,
.py-6 {
    padding-bottom: 4.5rem !important
}

.pl-6,
.px-6 {
    padding-left: 4.5rem !important
}

.p-7 {
    padding: 6.75rem !important
}

.pt-7,
.py-7 {
    padding-top: 6.75rem !important
}

.pr-7,
.px-7 {
    padding-right: 6.75rem !important
}

.pb-7,
.py-7 {
    padding-bottom: 6.75rem !important
}

.pl-7,
.px-7 {
    padding-left: 6.75rem !important
}

.p-8 {
    padding: 13.5rem !important
}

.pt-8,
.py-8 {
    padding-top: 13.5rem !important
}

.pr-8,
.px-8 {
    padding-right: 13.5rem !important
}

.pb-8,
.py-8 {
    padding-bottom: 13.5rem !important
}

.pl-8,
.px-8 {
    padding-left: 13.5rem !important
}

.m-auto {
    margin: auto !important
}

.mt-auto,
.my-auto {
    margin-top: auto !important
}

.mr-auto,
.mx-auto {
    margin-right: auto !important
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
    margin-left: auto !important
}

@media (min-width:576px) {
    .m-sm-0 {
        margin: 0 !important
    }
    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important
    }
    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important
    }
    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important
    }
    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important
    }
    .m-sm-1 {
        margin: .1875rem !important
    }
    .mt-sm-1,
    .my-sm-1 {
        margin-top: .1875rem !important
    }
    .mr-sm-1,
    .mx-sm-1 {
        margin-right: .1875rem !important
    }
    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: .1875rem !important
    }
    .ml-sm-1,
    .mx-sm-1 {
        margin-left: .1875rem !important
    }
    .m-sm-2 {
        margin: .375rem !important
    }
    .mt-sm-2,
    .my-sm-2 {
        margin-top: .375rem !important
    }
    .mr-sm-2,
    .mx-sm-2 {
        margin-right: .375rem !important
    }
    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: .375rem !important
    }
    .ml-sm-2,
    .mx-sm-2 {
        margin-left: .375rem !important
    }
    .m-sm-3 {
        margin: .75rem !important
    }
    .mt-sm-3,
    .my-sm-3 {
        margin-top: .75rem !important
    }
    .mr-sm-3,
    .mx-sm-3 {
        margin-right: .75rem !important
    }
    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: .75rem !important
    }
    .ml-sm-3,
    .mx-sm-3 {
        margin-left: .75rem !important
    }
    .m-sm-4 {
        margin: 1.5rem !important
    }
    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important
    }
    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important
    }
    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important
    }
    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important
    }
    .m-sm-5 {
        margin: 2.25rem !important
    }
    .mt-sm-5,
    .my-sm-5 {
        margin-top: 2.25rem !important
    }
    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 2.25rem !important
    }
    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 2.25rem !important
    }
    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 2.25rem !important
    }
    .m-sm--8 {
        margin: -13.5rem !important
    }
    .mt-sm--8,
    .my-sm--8 {
        margin-top: -13.5rem !important
    }
    .mr-sm--8,
    .mx-sm--8 {
        margin-right: -13.5rem !important
    }
    .mb-sm--8,
    .my-sm--8 {
        margin-bottom: -13.5rem !important
    }
    .ml-sm--8,
    .mx-sm--8 {
        margin-left: -13.5rem !important
    }
    .m-sm--7 {
        margin: -6.75rem !important
    }
    .mt-sm--7,
    .my-sm--7 {
        margin-top: -6.75rem !important
    }
    .mr-sm--7,
    .mx-sm--7 {
        margin-right: -6.75rem !important
    }
    .mb-sm--7,
    .my-sm--7 {
        margin-bottom: -6.75rem !important
    }
    .ml-sm--7,
    .mx-sm--7 {
        margin-left: -6.75rem !important
    }
    .m-sm--6 {
        margin: -4.5rem !important
    }
    .mt-sm--6,
    .my-sm--6 {
        margin-top: -4.5rem !important
    }
    .mr-sm--6,
    .mx-sm--6 {
        margin-right: -4.5rem !important
    }
    .mb-sm--6,
    .my-sm--6 {
        margin-bottom: -4.5rem !important
    }
    .ml-sm--6,
    .mx-sm--6 {
        margin-left: -4.5rem !important
    }
    .m-sm--5 {
        margin: -2.25rem !important
    }
    .mt-sm--5,
    .my-sm--5 {
        margin-top: -2.25rem !important
    }
    .mr-sm--5,
    .mx-sm--5 {
        margin-right: -2.25rem !important
    }
    .mb-sm--5,
    .my-sm--5 {
        margin-bottom: -2.25rem !important
    }
    .ml-sm--5,
    .mx-sm--5 {
        margin-left: -2.25rem !important
    }
    .m-sm--4 {
        margin: -1.5rem !important
    }
    .mt-sm--4,
    .my-sm--4 {
        margin-top: -1.5rem !important
    }
    .mr-sm--4,
    .mx-sm--4 {
        margin-right: -1.5rem !important
    }
    .mb-sm--4,
    .my-sm--4 {
        margin-bottom: -1.5rem !important
    }
    .ml-sm--4,
    .mx-sm--4 {
        margin-left: -1.5rem !important
    }
    .m-sm--3 {
        margin: -.75rem !important
    }
    .mt-sm--3,
    .my-sm--3 {
        margin-top: -.75rem !important
    }
    .mr-sm--3,
    .mx-sm--3 {
        margin-right: -.75rem !important
    }
    .mb-sm--3,
    .my-sm--3 {
        margin-bottom: -.75rem !important
    }
    .ml-sm--3,
    .mx-sm--3 {
        margin-left: -.75rem !important
    }
    .m-sm--2 {
        margin: -.375rem !important
    }
    .mt-sm--2,
    .my-sm--2 {
        margin-top: -.375rem !important
    }
    .mr-sm--2,
    .mx-sm--2 {
        margin-right: -.375rem !important
    }
    .mb-sm--2,
    .my-sm--2 {
        margin-bottom: -.375rem !important
    }
    .ml-sm--2,
    .mx-sm--2 {
        margin-left: -.375rem !important
    }
    .m-sm--1 {
        margin: -.1875rem !important
    }
    .mt-sm--1,
    .my-sm--1 {
        margin-top: -.1875rem !important
    }
    .mr-sm--1,
    .mx-sm--1 {
        margin-right: -.1875rem !important
    }
    .mb-sm--1,
    .my-sm--1 {
        margin-bottom: -.1875rem !important
    }
    .ml-sm--1,
    .mx-sm--1 {
        margin-left: -.1875rem !important
    }
    .m-sm-6 {
        margin: 4.5rem !important
    }
    .mt-sm-6,
    .my-sm-6 {
        margin-top: 4.5rem !important
    }
    .mr-sm-6,
    .mx-sm-6 {
        margin-right: 4.5rem !important
    }
    .mb-sm-6,
    .my-sm-6 {
        margin-bottom: 4.5rem !important
    }
    .ml-sm-6,
    .mx-sm-6 {
        margin-left: 4.5rem !important
    }
    .m-sm-7 {
        margin: 6.75rem !important
    }
    .mt-sm-7,
    .my-sm-7 {
        margin-top: 6.75rem !important
    }
    .mr-sm-7,
    .mx-sm-7 {
        margin-right: 6.75rem !important
    }
    .mb-sm-7,
    .my-sm-7 {
        margin-bottom: 6.75rem !important
    }
    .ml-sm-7,
    .mx-sm-7 {
        margin-left: 6.75rem !important
    }
    .m-sm-8 {
        margin: 13.5rem !important
    }
    .mt-sm-8,
    .my-sm-8 {
        margin-top: 13.5rem !important
    }
    .mr-sm-8,
    .mx-sm-8 {
        margin-right: 13.5rem !important
    }
    .mb-sm-8,
    .my-sm-8 {
        margin-bottom: 13.5rem !important
    }
    .ml-sm-8,
    .mx-sm-8 {
        margin-left: 13.5rem !important
    }
    .p-sm-0 {
        padding: 0 !important
    }
    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important
    }
    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important
    }
    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important
    }
    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important
    }
    .p-sm-1 {
        padding: .1875rem !important
    }
    .pt-sm-1,
    .py-sm-1 {
        padding-top: .1875rem !important
    }
    .pr-sm-1,
    .px-sm-1 {
        padding-right: .1875rem !important
    }
    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: .1875rem !important
    }
    .pl-sm-1,
    .px-sm-1 {
        padding-left: .1875rem !important
    }
    .p-sm-2 {
        padding: .375rem !important
    }
    .pt-sm-2,
    .py-sm-2 {
        padding-top: .375rem !important
    }
    .pr-sm-2,
    .px-sm-2 {
        padding-right: .375rem !important
    }
    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: .375rem !important
    }
    .pl-sm-2,
    .px-sm-2 {
        padding-left: .375rem !important
    }
    .p-sm-3 {
        padding: .75rem !important
    }
    .pt-sm-3,
    .py-sm-3 {
        padding-top: .75rem !important
    }
    .pr-sm-3,
    .px-sm-3 {
        padding-right: .75rem !important
    }
    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: .75rem !important
    }
    .pl-sm-3,
    .px-sm-3 {
        padding-left: .75rem !important
    }
    .p-sm-4 {
        padding: 1.5rem !important
    }
    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important
    }
    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important
    }
    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important
    }
    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important
    }
    .p-sm-5 {
        padding: 2.25rem !important
    }
    .pt-sm-5,
    .py-sm-5 {
        padding-top: 2.25rem !important
    }
    .pr-sm-5,
    .px-sm-5 {
        padding-right: 2.25rem !important
    }
    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 2.25rem !important
    }
    .pl-sm-5,
    .px-sm-5 {
        padding-left: 2.25rem !important
    }
    .p-sm--8 {
        padding: -13.5rem !important
    }
    .pt-sm--8,
    .py-sm--8 {
        padding-top: -13.5rem !important
    }
    .pr-sm--8,
    .px-sm--8 {
        padding-right: -13.5rem !important
    }
    .pb-sm--8,
    .py-sm--8 {
        padding-bottom: -13.5rem !important
    }
    .pl-sm--8,
    .px-sm--8 {
        padding-left: -13.5rem !important
    }
    .p-sm--7 {
        padding: -6.75rem !important
    }
    .pt-sm--7,
    .py-sm--7 {
        padding-top: -6.75rem !important
    }
    .pr-sm--7,
    .px-sm--7 {
        padding-right: -6.75rem !important
    }
    .pb-sm--7,
    .py-sm--7 {
        padding-bottom: -6.75rem !important
    }
    .pl-sm--7,
    .px-sm--7 {
        padding-left: -6.75rem !important
    }
    .p-sm--6 {
        padding: -4.5rem !important
    }
    .pt-sm--6,
    .py-sm--6 {
        padding-top: -4.5rem !important
    }
    .pr-sm--6,
    .px-sm--6 {
        padding-right: -4.5rem !important
    }
    .pb-sm--6,
    .py-sm--6 {
        padding-bottom: -4.5rem !important
    }
    .pl-sm--6,
    .px-sm--6 {
        padding-left: -4.5rem !important
    }
    .p-sm--5 {
        padding: -2.25rem !important
    }
    .pt-sm--5,
    .py-sm--5 {
        padding-top: -2.25rem !important
    }
    .pr-sm--5,
    .px-sm--5 {
        padding-right: -2.25rem !important
    }
    .pb-sm--5,
    .py-sm--5 {
        padding-bottom: -2.25rem !important
    }
    .pl-sm--5,
    .px-sm--5 {
        padding-left: -2.25rem !important
    }
    .p-sm--4 {
        padding: -1.5rem !important
    }
    .pt-sm--4,
    .py-sm--4 {
        padding-top: -1.5rem !important
    }
    .pr-sm--4,
    .px-sm--4 {
        padding-right: -1.5rem !important
    }
    .pb-sm--4,
    .py-sm--4 {
        padding-bottom: -1.5rem !important
    }
    .pl-sm--4,
    .px-sm--4 {
        padding-left: -1.5rem !important
    }
    .p-sm--3 {
        padding: -.75rem !important
    }
    .pt-sm--3,
    .py-sm--3 {
        padding-top: -.75rem !important
    }
    .pr-sm--3,
    .px-sm--3 {
        padding-right: -.75rem !important
    }
    .pb-sm--3,
    .py-sm--3 {
        padding-bottom: -.75rem !important
    }
    .pl-sm--3,
    .px-sm--3 {
        padding-left: -.75rem !important
    }
    .p-sm--2 {
        padding: -.375rem !important
    }
    .pt-sm--2,
    .py-sm--2 {
        padding-top: -.375rem !important
    }
    .pr-sm--2,
    .px-sm--2 {
        padding-right: -.375rem !important
    }
    .pb-sm--2,
    .py-sm--2 {
        padding-bottom: -.375rem !important
    }
    .pl-sm--2,
    .px-sm--2 {
        padding-left: -.375rem !important
    }
    .p-sm--1 {
        padding: -.1875rem !important
    }
    .pt-sm--1,
    .py-sm--1 {
        padding-top: -.1875rem !important
    }
    .pr-sm--1,
    .px-sm--1 {
        padding-right: -.1875rem !important
    }
    .pb-sm--1,
    .py-sm--1 {
        padding-bottom: -.1875rem !important
    }
    .pl-sm--1,
    .px-sm--1 {
        padding-left: -.1875rem !important
    }
    .p-sm-6 {
        padding: 4.5rem !important
    }
    .pt-sm-6,
    .py-sm-6 {
        padding-top: 4.5rem !important
    }
    .pr-sm-6,
    .px-sm-6 {
        padding-right: 4.5rem !important
    }
    .pb-sm-6,
    .py-sm-6 {
        padding-bottom: 4.5rem !important
    }
    .pl-sm-6,
    .px-sm-6 {
        padding-left: 4.5rem !important
    }
    .p-sm-7 {
        padding: 6.75rem !important
    }
    .pt-sm-7,
    .py-sm-7 {
        padding-top: 6.75rem !important
    }
    .pr-sm-7,
    .px-sm-7 {
        padding-right: 6.75rem !important
    }
    .pb-sm-7,
    .py-sm-7 {
        padding-bottom: 6.75rem !important
    }
    .pl-sm-7,
    .px-sm-7 {
        padding-left: 6.75rem !important
    }
    .p-sm-8 {
        padding: 13.5rem !important
    }
    .pt-sm-8,
    .py-sm-8 {
        padding-top: 13.5rem !important
    }
    .pr-sm-8,
    .px-sm-8 {
        padding-right: 13.5rem !important
    }
    .pb-sm-8,
    .py-sm-8 {
        padding-bottom: 13.5rem !important
    }
    .pl-sm-8,
    .px-sm-8 {
        padding-left: 13.5rem !important
    }
    .m-sm-auto {
        margin: auto !important
    }
    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important
    }
    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important
    }
    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important
    }
    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important
    }
}

@media (min-width:768px) {
    .m-md-0 {
        margin: 0 !important
    }
    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important
    }
    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important
    }
    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important
    }
    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important
    }
    .m-md-1 {
        margin: .1875rem !important
    }
    .mt-md-1,
    .my-md-1 {
        margin-top: .1875rem !important
    }
    .mr-md-1,
    .mx-md-1 {
        margin-right: .1875rem !important
    }
    .mb-md-1,
    .my-md-1 {
        margin-bottom: .1875rem !important
    }
    .ml-md-1,
    .mx-md-1 {
        margin-left: .1875rem !important
    }
    .m-md-2 {
        margin: .375rem !important
    }
    .mt-md-2,
    .my-md-2 {
        margin-top: .375rem !important
    }
    .mr-md-2,
    .mx-md-2 {
        margin-right: .375rem !important
    }
    .mb-md-2,
    .my-md-2 {
        margin-bottom: .375rem !important
    }
    .ml-md-2,
    .mx-md-2 {
        margin-left: .375rem !important
    }
    .m-md-3 {
        margin: .75rem !important
    }
    .mt-md-3,
    .my-md-3 {
        margin-top: .75rem !important
    }
    .mr-md-3,
    .mx-md-3 {
        margin-right: .75rem !important
    }
    .mb-md-3,
    .my-md-3 {
        margin-bottom: .75rem !important
    }
    .ml-md-3,
    .mx-md-3 {
        margin-left: .75rem !important
    }
    .m-md-4 {
        margin: 1.5rem !important
    }
    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important
    }
    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important
    }
    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important
    }
    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important
    }
    .m-md-5 {
        margin: 2.25rem !important
    }
    .mt-md-5,
    .my-md-5 {
        margin-top: 2.25rem !important
    }
    .mr-md-5,
    .mx-md-5 {
        margin-right: 2.25rem !important
    }
    .mb-md-5,
    .my-md-5 {
        margin-bottom: 2.25rem !important
    }
    .ml-md-5,
    .mx-md-5 {
        margin-left: 2.25rem !important
    }
    .m-md--8 {
        margin: -13.5rem !important
    }
    .mt-md--8,
    .my-md--8 {
        margin-top: -13.5rem !important
    }
    .mr-md--8,
    .mx-md--8 {
        margin-right: -13.5rem !important
    }
    .mb-md--8,
    .my-md--8 {
        margin-bottom: -13.5rem !important
    }
    .ml-md--8,
    .mx-md--8 {
        margin-left: -13.5rem !important
    }
    .m-md--7 {
        margin: -6.75rem !important
    }
    .mt-md--7,
    .my-md--7 {
        margin-top: -6.75rem !important
    }
    .mr-md--7,
    .mx-md--7 {
        margin-right: -6.75rem !important
    }
    .mb-md--7,
    .my-md--7 {
        margin-bottom: -6.75rem !important
    }
    .ml-md--7,
    .mx-md--7 {
        margin-left: -6.75rem !important
    }
    .m-md--6 {
        margin: -4.5rem !important
    }
    .mt-md--6,
    .my-md--6 {
        margin-top: -4.5rem !important
    }
    .mr-md--6,
    .mx-md--6 {
        margin-right: -4.5rem !important
    }
    .mb-md--6,
    .my-md--6 {
        margin-bottom: -4.5rem !important
    }
    .ml-md--6,
    .mx-md--6 {
        margin-left: -4.5rem !important
    }
    .m-md--5 {
        margin: -2.25rem !important
    }
    .mt-md--5,
    .my-md--5 {
        margin-top: -2.25rem !important
    }
    .mr-md--5,
    .mx-md--5 {
        margin-right: -2.25rem !important
    }
    .mb-md--5,
    .my-md--5 {
        margin-bottom: -2.25rem !important
    }
    .ml-md--5,
    .mx-md--5 {
        margin-left: -2.25rem !important
    }
    .m-md--4 {
        margin: -1.5rem !important
    }
    .mt-md--4,
    .my-md--4 {
        margin-top: -1.5rem !important
    }
    .mr-md--4,
    .mx-md--4 {
        margin-right: -1.5rem !important
    }
    .mb-md--4,
    .my-md--4 {
        margin-bottom: -1.5rem !important
    }
    .ml-md--4,
    .mx-md--4 {
        margin-left: -1.5rem !important
    }
    .m-md--3 {
        margin: -.75rem !important
    }
    .mt-md--3,
    .my-md--3 {
        margin-top: -.75rem !important
    }
    .mr-md--3,
    .mx-md--3 {
        margin-right: -.75rem !important
    }
    .mb-md--3,
    .my-md--3 {
        margin-bottom: -.75rem !important
    }
    .ml-md--3,
    .mx-md--3 {
        margin-left: -.75rem !important
    }
    .m-md--2 {
        margin: -.375rem !important
    }
    .mt-md--2,
    .my-md--2 {
        margin-top: -.375rem !important
    }
    .mr-md--2,
    .mx-md--2 {
        margin-right: -.375rem !important
    }
    .mb-md--2,
    .my-md--2 {
        margin-bottom: -.375rem !important
    }
    .ml-md--2,
    .mx-md--2 {
        margin-left: -.375rem !important
    }
    .m-md--1 {
        margin: -.1875rem !important
    }
    .mt-md--1,
    .my-md--1 {
        margin-top: -.1875rem !important
    }
    .mr-md--1,
    .mx-md--1 {
        margin-right: -.1875rem !important
    }
    .mb-md--1,
    .my-md--1 {
        margin-bottom: -.1875rem !important
    }
    .ml-md--1,
    .mx-md--1 {
        margin-left: -.1875rem !important
    }
    .m-md-6 {
        margin: 4.5rem !important
    }
    .mt-md-6,
    .my-md-6 {
        margin-top: 4.5rem !important
    }
    .mr-md-6,
    .mx-md-6 {
        margin-right: 4.5rem !important
    }
    .mb-md-6,
    .my-md-6 {
        margin-bottom: 4.5rem !important
    }
    .ml-md-6,
    .mx-md-6 {
        margin-left: 4.5rem !important
    }
    .m-md-7 {
        margin: 6.75rem !important
    }
    .mt-md-7,
    .my-md-7 {
        margin-top: 6.75rem !important
    }
    .mr-md-7,
    .mx-md-7 {
        margin-right: 6.75rem !important
    }
    .mb-md-7,
    .my-md-7 {
        margin-bottom: 6.75rem !important
    }
    .ml-md-7,
    .mx-md-7 {
        margin-left: 6.75rem !important
    }
    .m-md-8 {
        margin: 13.5rem !important
    }
    .mt-md-8,
    .my-md-8 {
        margin-top: 13.5rem !important
    }
    .mr-md-8,
    .mx-md-8 {
        margin-right: 13.5rem !important
    }
    .mb-md-8,
    .my-md-8 {
        margin-bottom: 13.5rem !important
    }
    .ml-md-8,
    .mx-md-8 {
        margin-left: 13.5rem !important
    }
    .p-md-0 {
        padding: 0 !important
    }
    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important
    }
    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important
    }
    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important
    }
    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important
    }
    .p-md-1 {
        padding: .1875rem !important
    }
    .pt-md-1,
    .py-md-1 {
        padding-top: .1875rem !important
    }
    .pr-md-1,
    .px-md-1 {
        padding-right: .1875rem !important
    }
    .pb-md-1,
    .py-md-1 {
        padding-bottom: .1875rem !important
    }
    .pl-md-1,
    .px-md-1 {
        padding-left: .1875rem !important
    }
    .p-md-2 {
        padding: .375rem !important
    }
    .pt-md-2,
    .py-md-2 {
        padding-top: .375rem !important
    }
    .pr-md-2,
    .px-md-2 {
        padding-right: .375rem !important
    }
    .pb-md-2,
    .py-md-2 {
        padding-bottom: .375rem !important
    }
    .pl-md-2,
    .px-md-2 {
        padding-left: .375rem !important
    }
    .p-md-3 {
        padding: .75rem !important
    }
    .pt-md-3,
    .py-md-3 {
        padding-top: .75rem !important
    }
    .pr-md-3,
    .px-md-3 {
        padding-right: .75rem !important
    }
    .pb-md-3,
    .py-md-3 {
        padding-bottom: .75rem !important
    }
    .pl-md-3,
    .px-md-3 {
        padding-left: .75rem !important
    }
    .p-md-4 {
        padding: 1.5rem !important
    }
    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important
    }
    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important
    }
    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important
    }
    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important
    }
    .p-md-5 {
        padding: 2.25rem !important
    }
    .pt-md-5,
    .py-md-5 {
        padding-top: 2.25rem !important
    }
    .pr-md-5,
    .px-md-5 {
        padding-right: 2.25rem !important
    }
    .pb-md-5,
    .py-md-5 {
        padding-bottom: 2.25rem !important
    }
    .pl-md-5,
    .px-md-5 {
        padding-left: 2.25rem !important
    }
    .p-md--8 {
        padding: -13.5rem !important
    }
    .pt-md--8,
    .py-md--8 {
        padding-top: -13.5rem !important
    }
    .pr-md--8,
    .px-md--8 {
        padding-right: -13.5rem !important
    }
    .pb-md--8,
    .py-md--8 {
        padding-bottom: -13.5rem !important
    }
    .pl-md--8,
    .px-md--8 {
        padding-left: -13.5rem !important
    }
    .p-md--7 {
        padding: -6.75rem !important
    }
    .pt-md--7,
    .py-md--7 {
        padding-top: -6.75rem !important
    }
    .pr-md--7,
    .px-md--7 {
        padding-right: -6.75rem !important
    }
    .pb-md--7,
    .py-md--7 {
        padding-bottom: -6.75rem !important
    }
    .pl-md--7,
    .px-md--7 {
        padding-left: -6.75rem !important
    }
    .p-md--6 {
        padding: -4.5rem !important
    }
    .pt-md--6,
    .py-md--6 {
        padding-top: -4.5rem !important
    }
    .pr-md--6,
    .px-md--6 {
        padding-right: -4.5rem !important
    }
    .pb-md--6,
    .py-md--6 {
        padding-bottom: -4.5rem !important
    }
    .pl-md--6,
    .px-md--6 {
        padding-left: -4.5rem !important
    }
    .p-md--5 {
        padding: -2.25rem !important
    }
    .pt-md--5,
    .py-md--5 {
        padding-top: -2.25rem !important
    }
    .pr-md--5,
    .px-md--5 {
        padding-right: -2.25rem !important
    }
    .pb-md--5,
    .py-md--5 {
        padding-bottom: -2.25rem !important
    }
    .pl-md--5,
    .px-md--5 {
        padding-left: -2.25rem !important
    }
    .p-md--4 {
        padding: -1.5rem !important
    }
    .pt-md--4,
    .py-md--4 {
        padding-top: -1.5rem !important
    }
    .pr-md--4,
    .px-md--4 {
        padding-right: -1.5rem !important
    }
    .pb-md--4,
    .py-md--4 {
        padding-bottom: -1.5rem !important
    }
    .pl-md--4,
    .px-md--4 {
        padding-left: -1.5rem !important
    }
    .p-md--3 {
        padding: -.75rem !important
    }
    .pt-md--3,
    .py-md--3 {
        padding-top: -.75rem !important
    }
    .pr-md--3,
    .px-md--3 {
        padding-right: -.75rem !important
    }
    .pb-md--3,
    .py-md--3 {
        padding-bottom: -.75rem !important
    }
    .pl-md--3,
    .px-md--3 {
        padding-left: -.75rem !important
    }
    .p-md--2 {
        padding: -.375rem !important
    }
    .pt-md--2,
    .py-md--2 {
        padding-top: -.375rem !important
    }
    .pr-md--2,
    .px-md--2 {
        padding-right: -.375rem !important
    }
    .pb-md--2,
    .py-md--2 {
        padding-bottom: -.375rem !important
    }
    .pl-md--2,
    .px-md--2 {
        padding-left: -.375rem !important
    }
    .p-md--1 {
        padding: -.1875rem !important
    }
    .pt-md--1,
    .py-md--1 {
        padding-top: -.1875rem !important
    }
    .pr-md--1,
    .px-md--1 {
        padding-right: -.1875rem !important
    }
    .pb-md--1,
    .py-md--1 {
        padding-bottom: -.1875rem !important
    }
    .pl-md--1,
    .px-md--1 {
        padding-left: -.1875rem !important
    }
    .p-md-6 {
        padding: 4.5rem !important
    }
    .pt-md-6,
    .py-md-6 {
        padding-top: 4.5rem !important
    }
    .pr-md-6,
    .px-md-6 {
        padding-right: 4.5rem !important
    }
    .pb-md-6,
    .py-md-6 {
        padding-bottom: 4.5rem !important
    }
    .pl-md-6,
    .px-md-6 {
        padding-left: 4.5rem !important
    }
    .p-md-7 {
        padding: 6.75rem !important
    }
    .pt-md-7,
    .py-md-7 {
        padding-top: 6.75rem !important
    }
    .pr-md-7,
    .px-md-7 {
        padding-right: 6.75rem !important
    }
    .pb-md-7,
    .py-md-7 {
        padding-bottom: 6.75rem !important
    }
    .pl-md-7,
    .px-md-7 {
        padding-left: 6.75rem !important
    }
    .p-md-8 {
        padding: 13.5rem !important
    }
    .pt-md-8,
    .py-md-8 {
        padding-top: 13.5rem !important
    }
    .pr-md-8,
    .px-md-8 {
        padding-right: 13.5rem !important
    }
    .pb-md-8,
    .py-md-8 {
        padding-bottom: 13.5rem !important
    }
    .pl-md-8,
    .px-md-8 {
        padding-left: 13.5rem !important
    }
    .m-md-auto {
        margin: auto !important
    }
    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important
    }
    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important
    }
    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important
    }
    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important
    }
}

@media (min-width:992px) {
    .m-lg-0 {
        margin: 0 !important
    }
    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important
    }
    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important
    }
    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important
    }
    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important
    }
    .m-lg-1 {
        margin: .1875rem !important
    }
    .mt-lg-1,
    .my-lg-1 {
        margin-top: .1875rem !important
    }
    .mr-lg-1,
    .mx-lg-1 {
        margin-right: .1875rem !important
    }
    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: .1875rem !important
    }
    .ml-lg-1,
    .mx-lg-1 {
        margin-left: .1875rem !important
    }
    .m-lg-2 {
        margin: .375rem !important
    }
    .mt-lg-2,
    .my-lg-2 {
        margin-top: .375rem !important
    }
    .mr-lg-2,
    .mx-lg-2 {
        margin-right: .375rem !important
    }
    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: .375rem !important
    }
    .ml-lg-2,
    .mx-lg-2 {
        margin-left: .375rem !important
    }
    .m-lg-3 {
        margin: .75rem !important
    }
    .mt-lg-3,
    .my-lg-3 {
        margin-top: .75rem !important
    }
    .mr-lg-3,
    .mx-lg-3 {
        margin-right: .75rem !important
    }
    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: .75rem !important
    }
    .ml-lg-3,
    .mx-lg-3 {
        margin-left: .75rem !important
    }
    .m-lg-4 {
        margin: 1.5rem !important
    }
    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important
    }
    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important
    }
    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important
    }
    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important
    }
    .m-lg-5 {
        margin: 2.25rem !important
    }
    .mt-lg-5,
    .my-lg-5 {
        margin-top: 2.25rem !important
    }
    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 2.25rem !important
    }
    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 2.25rem !important
    }
    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 2.25rem !important
    }
    .m-lg--8 {
        margin: -13.5rem !important
    }
    .mt-lg--8,
    .my-lg--8 {
        margin-top: -13.5rem !important
    }
    .mr-lg--8,
    .mx-lg--8 {
        margin-right: -13.5rem !important
    }
    .mb-lg--8,
    .my-lg--8 {
        margin-bottom: -13.5rem !important
    }
    .ml-lg--8,
    .mx-lg--8 {
        margin-left: -13.5rem !important
    }
    .m-lg--7 {
        margin: -6.75rem !important
    }
    .mt-lg--7,
    .my-lg--7 {
        margin-top: -6.75rem !important
    }
    .mr-lg--7,
    .mx-lg--7 {
        margin-right: -6.75rem !important
    }
    .mb-lg--7,
    .my-lg--7 {
        margin-bottom: -6.75rem !important
    }
    .ml-lg--7,
    .mx-lg--7 {
        margin-left: -6.75rem !important
    }
    .m-lg--6 {
        margin: -4.5rem !important
    }
    .mt-lg--6,
    .my-lg--6 {
        margin-top: -4.5rem !important
    }
    .mr-lg--6,
    .mx-lg--6 {
        margin-right: -4.5rem !important
    }
    .mb-lg--6,
    .my-lg--6 {
        margin-bottom: -4.5rem !important
    }
    .ml-lg--6,
    .mx-lg--6 {
        margin-left: -4.5rem !important
    }
    .m-lg--5 {
        margin: -2.25rem !important
    }
    .mt-lg--5,
    .my-lg--5 {
        margin-top: -2.25rem !important
    }
    .mr-lg--5,
    .mx-lg--5 {
        margin-right: -2.25rem !important
    }
    .mb-lg--5,
    .my-lg--5 {
        margin-bottom: -2.25rem !important
    }
    .ml-lg--5,
    .mx-lg--5 {
        margin-left: -2.25rem !important
    }
    .m-lg--4 {
        margin: -1.5rem !important
    }
    .mt-lg--4,
    .my-lg--4 {
        margin-top: -1.5rem !important
    }
    .mr-lg--4,
    .mx-lg--4 {
        margin-right: -1.5rem !important
    }
    .mb-lg--4,
    .my-lg--4 {
        margin-bottom: -1.5rem !important
    }
    .ml-lg--4,
    .mx-lg--4 {
        margin-left: -1.5rem !important
    }
    .m-lg--3 {
        margin: -.75rem !important
    }
    .mt-lg--3,
    .my-lg--3 {
        margin-top: -.75rem !important
    }
    .mr-lg--3,
    .mx-lg--3 {
        margin-right: -.75rem !important
    }
    .mb-lg--3,
    .my-lg--3 {
        margin-bottom: -.75rem !important
    }
    .ml-lg--3,
    .mx-lg--3 {
        margin-left: -.75rem !important
    }
    .m-lg--2 {
        margin: -.375rem !important
    }
    .mt-lg--2,
    .my-lg--2 {
        margin-top: -.375rem !important
    }
    .mr-lg--2,
    .mx-lg--2 {
        margin-right: -.375rem !important
    }
    .mb-lg--2,
    .my-lg--2 {
        margin-bottom: -.375rem !important
    }
    .ml-lg--2,
    .mx-lg--2 {
        margin-left: -.375rem !important
    }
    .m-lg--1 {
        margin: -.1875rem !important
    }
    .mt-lg--1,
    .my-lg--1 {
        margin-top: -.1875rem !important
    }
    .mr-lg--1,
    .mx-lg--1 {
        margin-right: -.1875rem !important
    }
    .mb-lg--1,
    .my-lg--1 {
        margin-bottom: -.1875rem !important
    }
    .ml-lg--1,
    .mx-lg--1 {
        margin-left: -.1875rem !important
    }
    .m-lg-6 {
        margin: 4.5rem !important
    }
    .mt-lg-6,
    .my-lg-6 {
        margin-top: 4.5rem !important
    }
    .mr-lg-6,
    .mx-lg-6 {
        margin-right: 4.5rem !important
    }
    .mb-lg-6,
    .my-lg-6 {
        margin-bottom: 4.5rem !important
    }
    .ml-lg-6,
    .mx-lg-6 {
        margin-left: 4.5rem !important
    }
    .m-lg-7 {
        margin: 6.75rem !important
    }
    .mt-lg-7,
    .my-lg-7 {
        margin-top: 6.75rem !important
    }
    .mr-lg-7,
    .mx-lg-7 {
        margin-right: 6.75rem !important
    }
    .mb-lg-7,
    .my-lg-7 {
        margin-bottom: 6.75rem !important
    }
    .ml-lg-7,
    .mx-lg-7 {
        margin-left: 6.75rem !important
    }
    .m-lg-8 {
        margin: 13.5rem !important
    }
    .mt-lg-8,
    .my-lg-8 {
        margin-top: 13.5rem !important
    }
    .mr-lg-8,
    .mx-lg-8 {
        margin-right: 13.5rem !important
    }
    .mb-lg-8,
    .my-lg-8 {
        margin-bottom: 13.5rem !important
    }
    .ml-lg-8,
    .mx-lg-8 {
        margin-left: 13.5rem !important
    }
    .p-lg-0 {
        padding: 0 !important
    }
    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important
    }
    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important
    }
    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important
    }
    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important
    }
    .p-lg-1 {
        padding: .1875rem !important
    }
    .pt-lg-1,
    .py-lg-1 {
        padding-top: .1875rem !important
    }
    .pr-lg-1,
    .px-lg-1 {
        padding-right: .1875rem !important
    }
    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: .1875rem !important
    }
    .pl-lg-1,
    .px-lg-1 {
        padding-left: .1875rem !important
    }
    .p-lg-2 {
        padding: .375rem !important
    }
    .pt-lg-2,
    .py-lg-2 {
        padding-top: .375rem !important
    }
    .pr-lg-2,
    .px-lg-2 {
        padding-right: .375rem !important
    }
    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: .375rem !important
    }
    .pl-lg-2,
    .px-lg-2 {
        padding-left: .375rem !important
    }
    .p-lg-3 {
        padding: .75rem !important
    }
    .pt-lg-3,
    .py-lg-3 {
        padding-top: .75rem !important
    }
    .pr-lg-3,
    .px-lg-3 {
        padding-right: .75rem !important
    }
    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: .75rem !important
    }
    .pl-lg-3,
    .px-lg-3 {
        padding-left: .75rem !important
    }
    .p-lg-4 {
        padding: 1.5rem !important
    }
    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important
    }
    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important
    }
    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important
    }
    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important
    }
    .p-lg-5 {
        padding: 2.25rem !important
    }
    .pt-lg-5,
    .py-lg-5 {
        padding-top: 2.25rem !important
    }
    .pr-lg-5,
    .px-lg-5 {
        padding-right: 2.25rem !important
    }
    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 2.25rem !important
    }
    .pl-lg-5,
    .px-lg-5 {
        padding-left: 2.25rem !important
    }
    .p-lg--8 {
        padding: -13.5rem !important
    }
    .pt-lg--8,
    .py-lg--8 {
        padding-top: -13.5rem !important
    }
    .pr-lg--8,
    .px-lg--8 {
        padding-right: -13.5rem !important
    }
    .pb-lg--8,
    .py-lg--8 {
        padding-bottom: -13.5rem !important
    }
    .pl-lg--8,
    .px-lg--8 {
        padding-left: -13.5rem !important
    }
    .p-lg--7 {
        padding: -6.75rem !important
    }
    .pt-lg--7,
    .py-lg--7 {
        padding-top: -6.75rem !important
    }
    .pr-lg--7,
    .px-lg--7 {
        padding-right: -6.75rem !important
    }
    .pb-lg--7,
    .py-lg--7 {
        padding-bottom: -6.75rem !important
    }
    .pl-lg--7,
    .px-lg--7 {
        padding-left: -6.75rem !important
    }
    .p-lg--6 {
        padding: -4.5rem !important
    }
    .pt-lg--6,
    .py-lg--6 {
        padding-top: -4.5rem !important
    }
    .pr-lg--6,
    .px-lg--6 {
        padding-right: -4.5rem !important
    }
    .pb-lg--6,
    .py-lg--6 {
        padding-bottom: -4.5rem !important
    }
    .pl-lg--6,
    .px-lg--6 {
        padding-left: -4.5rem !important
    }
    .p-lg--5 {
        padding: -2.25rem !important
    }
    .pt-lg--5,
    .py-lg--5 {
        padding-top: -2.25rem !important
    }
    .pr-lg--5,
    .px-lg--5 {
        padding-right: -2.25rem !important
    }
    .pb-lg--5,
    .py-lg--5 {
        padding-bottom: -2.25rem !important
    }
    .pl-lg--5,
    .px-lg--5 {
        padding-left: -2.25rem !important
    }
    .p-lg--4 {
        padding: -1.5rem !important
    }
    .pt-lg--4,
    .py-lg--4 {
        padding-top: -1.5rem !important
    }
    .pr-lg--4,
    .px-lg--4 {
        padding-right: -1.5rem !important
    }
    .pb-lg--4,
    .py-lg--4 {
        padding-bottom: -1.5rem !important
    }
    .pl-lg--4,
    .px-lg--4 {
        padding-left: -1.5rem !important
    }
    .p-lg--3 {
        padding: -.75rem !important
    }
    .pt-lg--3,
    .py-lg--3 {
        padding-top: -.75rem !important
    }
    .pr-lg--3,
    .px-lg--3 {
        padding-right: -.75rem !important
    }
    .pb-lg--3,
    .py-lg--3 {
        padding-bottom: -.75rem !important
    }
    .pl-lg--3,
    .px-lg--3 {
        padding-left: -.75rem !important
    }
    .p-lg--2 {
        padding: -.375rem !important
    }
    .pt-lg--2,
    .py-lg--2 {
        padding-top: -.375rem !important
    }
    .pr-lg--2,
    .px-lg--2 {
        padding-right: -.375rem !important
    }
    .pb-lg--2,
    .py-lg--2 {
        padding-bottom: -.375rem !important
    }
    .pl-lg--2,
    .px-lg--2 {
        padding-left: -.375rem !important
    }
    .p-lg--1 {
        padding: -.1875rem !important
    }
    .pt-lg--1,
    .py-lg--1 {
        padding-top: -.1875rem !important
    }
    .pr-lg--1,
    .px-lg--1 {
        padding-right: -.1875rem !important
    }
    .pb-lg--1,
    .py-lg--1 {
        padding-bottom: -.1875rem !important
    }
    .pl-lg--1,
    .px-lg--1 {
        padding-left: -.1875rem !important
    }
    .p-lg-6 {
        padding: 4.5rem !important
    }
    .pt-lg-6,
    .py-lg-6 {
        padding-top: 4.5rem !important
    }
    .pr-lg-6,
    .px-lg-6 {
        padding-right: 4.5rem !important
    }
    .pb-lg-6,
    .py-lg-6 {
        padding-bottom: 4.5rem !important
    }
    .pl-lg-6,
    .px-lg-6 {
        padding-left: 4.5rem !important
    }
    .p-lg-7 {
        padding: 6.75rem !important
    }
    .pt-lg-7,
    .py-lg-7 {
        padding-top: 6.75rem !important
    }
    .pr-lg-7,
    .px-lg-7 {
        padding-right: 6.75rem !important
    }
    .pb-lg-7,
    .py-lg-7 {
        padding-bottom: 6.75rem !important
    }
    .pl-lg-7,
    .px-lg-7 {
        padding-left: 6.75rem !important
    }
    .p-lg-8 {
        padding: 13.5rem !important
    }
    .pt-lg-8,
    .py-lg-8 {
        padding-top: 13.5rem !important
    }
    .pr-lg-8,
    .px-lg-8 {
        padding-right: 13.5rem !important
    }
    .pb-lg-8,
    .py-lg-8 {
        padding-bottom: 13.5rem !important
    }
    .pl-lg-8,
    .px-lg-8 {
        padding-left: 13.5rem !important
    }
    .m-lg-auto {
        margin: auto !important
    }
    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important
    }
    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important
    }
    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important
    }
    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important
    }
}

@media (min-width:1200px) {
    .m-xl-0 {
        margin: 0 !important
    }
    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important
    }
    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important
    }
    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important
    }
    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important
    }
    .m-xl-1 {
        margin: .1875rem !important
    }
    .mt-xl-1,
    .my-xl-1 {
        margin-top: .1875rem !important
    }
    .mr-xl-1,
    .mx-xl-1 {
        margin-right: .1875rem !important
    }
    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: .1875rem !important
    }
    .ml-xl-1,
    .mx-xl-1 {
        margin-left: .1875rem !important
    }
    .m-xl-2 {
        margin: .375rem !important
    }
    .mt-xl-2,
    .my-xl-2 {
        margin-top: .375rem !important
    }
    .mr-xl-2,
    .mx-xl-2 {
        margin-right: .375rem !important
    }
    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: .375rem !important
    }
    .ml-xl-2,
    .mx-xl-2 {
        margin-left: .375rem !important
    }
    .m-xl-3 {
        margin: .75rem !important
    }
    .mt-xl-3,
    .my-xl-3 {
        margin-top: .75rem !important
    }
    .mr-xl-3,
    .mx-xl-3 {
        margin-right: .75rem !important
    }
    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: .75rem !important
    }
    .ml-xl-3,
    .mx-xl-3 {
        margin-left: .75rem !important
    }
    .m-xl-4 {
        margin: 1.5rem !important
    }
    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important
    }
    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important
    }
    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important
    }
    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important
    }
    .m-xl-5 {
        margin: 2.25rem !important
    }
    .mt-xl-5,
    .my-xl-5 {
        margin-top: 2.25rem !important
    }
    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 2.25rem !important
    }
    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 2.25rem !important
    }
    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 2.25rem !important
    }
    .m-xl--8 {
        margin: -13.5rem !important
    }
    .mt-xl--8,
    .my-xl--8 {
        margin-top: -13.5rem !important
    }
    .mr-xl--8,
    .mx-xl--8 {
        margin-right: -13.5rem !important
    }
    .mb-xl--8,
    .my-xl--8 {
        margin-bottom: -13.5rem !important
    }
    .ml-xl--8,
    .mx-xl--8 {
        margin-left: -13.5rem !important
    }
    .m-xl--7 {
        margin: -6.75rem !important
    }
    .mt-xl--7,
    .my-xl--7 {
        margin-top: -6.75rem !important
    }
    .mr-xl--7,
    .mx-xl--7 {
        margin-right: -6.75rem !important
    }
    .mb-xl--7,
    .my-xl--7 {
        margin-bottom: -6.75rem !important
    }
    .ml-xl--7,
    .mx-xl--7 {
        margin-left: -6.75rem !important
    }
    .m-xl--6 {
        margin: -4.5rem !important
    }
    .mt-xl--6,
    .my-xl--6 {
        margin-top: -4.5rem !important
    }
    .mr-xl--6,
    .mx-xl--6 {
        margin-right: -4.5rem !important
    }
    .mb-xl--6,
    .my-xl--6 {
        margin-bottom: -4.5rem !important
    }
    .ml-xl--6,
    .mx-xl--6 {
        margin-left: -4.5rem !important
    }
    .m-xl--5 {
        margin: -2.25rem !important
    }
    .mt-xl--5,
    .my-xl--5 {
        margin-top: -2.25rem !important
    }
    .mr-xl--5,
    .mx-xl--5 {
        margin-right: -2.25rem !important
    }
    .mb-xl--5,
    .my-xl--5 {
        margin-bottom: -2.25rem !important
    }
    .ml-xl--5,
    .mx-xl--5 {
        margin-left: -2.25rem !important
    }
    .m-xl--4 {
        margin: -1.5rem !important
    }
    .mt-xl--4,
    .my-xl--4 {
        margin-top: -1.5rem !important
    }
    .mr-xl--4,
    .mx-xl--4 {
        margin-right: -1.5rem !important
    }
    .mb-xl--4,
    .my-xl--4 {
        margin-bottom: -1.5rem !important
    }
    .ml-xl--4,
    .mx-xl--4 {
        margin-left: -1.5rem !important
    }
    .m-xl--3 {
        margin: -.75rem !important
    }
    .mt-xl--3,
    .my-xl--3 {
        margin-top: -.75rem !important
    }
    .mr-xl--3,
    .mx-xl--3 {
        margin-right: -.75rem !important
    }
    .mb-xl--3,
    .my-xl--3 {
        margin-bottom: -.75rem !important
    }
    .ml-xl--3,
    .mx-xl--3 {
        margin-left: -.75rem !important
    }
    .m-xl--2 {
        margin: -.375rem !important
    }
    .mt-xl--2,
    .my-xl--2 {
        margin-top: -.375rem !important
    }
    .mr-xl--2,
    .mx-xl--2 {
        margin-right: -.375rem !important
    }
    .mb-xl--2,
    .my-xl--2 {
        margin-bottom: -.375rem !important
    }
    .ml-xl--2,
    .mx-xl--2 {
        margin-left: -.375rem !important
    }
    .m-xl--1 {
        margin: -.1875rem !important
    }
    .mt-xl--1,
    .my-xl--1 {
        margin-top: -.1875rem !important
    }
    .mr-xl--1,
    .mx-xl--1 {
        margin-right: -.1875rem !important
    }
    .mb-xl--1,
    .my-xl--1 {
        margin-bottom: -.1875rem !important
    }
    .ml-xl--1,
    .mx-xl--1 {
        margin-left: -.1875rem !important
    }
    .m-xl-6 {
        margin: 4.5rem !important
    }
    .mt-xl-6,
    .my-xl-6 {
        margin-top: 4.5rem !important
    }
    .mr-xl-6,
    .mx-xl-6 {
        margin-right: 4.5rem !important
    }
    .mb-xl-6,
    .my-xl-6 {
        margin-bottom: 4.5rem !important
    }
    .ml-xl-6,
    .mx-xl-6 {
        margin-left: 4.5rem !important
    }
    .m-xl-7 {
        margin: 6.75rem !important
    }
    .mt-xl-7,
    .my-xl-7 {
        margin-top: 6.75rem !important
    }
    .mr-xl-7,
    .mx-xl-7 {
        margin-right: 6.75rem !important
    }
    .mb-xl-7,
    .my-xl-7 {
        margin-bottom: 6.75rem !important
    }
    .ml-xl-7,
    .mx-xl-7 {
        margin-left: 6.75rem !important
    }
    .m-xl-8 {
        margin: 13.5rem !important
    }
    .mt-xl-8,
    .my-xl-8 {
        margin-top: 13.5rem !important
    }
    .mr-xl-8,
    .mx-xl-8 {
        margin-right: 13.5rem !important
    }
    .mb-xl-8,
    .my-xl-8 {
        margin-bottom: 13.5rem !important
    }
    .ml-xl-8,
    .mx-xl-8 {
        margin-left: 13.5rem !important
    }
    .p-xl-0 {
        padding: 0 !important
    }
    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important
    }
    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important
    }
    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important
    }
    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important
    }
    .p-xl-1 {
        padding: .1875rem !important
    }
    .pt-xl-1,
    .py-xl-1 {
        padding-top: .1875rem !important
    }
    .pr-xl-1,
    .px-xl-1 {
        padding-right: .1875rem !important
    }
    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: .1875rem !important
    }
    .pl-xl-1,
    .px-xl-1 {
        padding-left: .1875rem !important
    }
    .p-xl-2 {
        padding: .375rem !important
    }
    .pt-xl-2,
    .py-xl-2 {
        padding-top: .375rem !important
    }
    .pr-xl-2,
    .px-xl-2 {
        padding-right: .375rem !important
    }
    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: .375rem !important
    }
    .pl-xl-2,
    .px-xl-2 {
        padding-left: .375rem !important
    }
    .p-xl-3 {
        padding: .75rem !important
    }
    .pt-xl-3,
    .py-xl-3 {
        padding-top: .75rem !important
    }
    .pr-xl-3,
    .px-xl-3 {
        padding-right: .75rem !important
    }
    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: .75rem !important
    }
    .pl-xl-3,
    .px-xl-3 {
        padding-left: .75rem !important
    }
    .p-xl-4 {
        padding: 1.5rem !important
    }
    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important
    }
    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important
    }
    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important
    }
    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important
    }
    .p-xl-5 {
        padding: 2.25rem !important
    }
    .pt-xl-5,
    .py-xl-5 {
        padding-top: 2.25rem !important
    }
    .pr-xl-5,
    .px-xl-5 {
        padding-right: 2.25rem !important
    }
    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 2.25rem !important
    }
    .pl-xl-5,
    .px-xl-5 {
        padding-left: 2.25rem !important
    }
    .p-xl--8 {
        padding: -13.5rem !important
    }
    .pt-xl--8,
    .py-xl--8 {
        padding-top: -13.5rem !important
    }
    .pr-xl--8,
    .px-xl--8 {
        padding-right: -13.5rem !important
    }
    .pb-xl--8,
    .py-xl--8 {
        padding-bottom: -13.5rem !important
    }
    .pl-xl--8,
    .px-xl--8 {
        padding-left: -13.5rem !important
    }
    .p-xl--7 {
        padding: -6.75rem !important
    }
    .pt-xl--7,
    .py-xl--7 {
        padding-top: -6.75rem !important
    }
    .pr-xl--7,
    .px-xl--7 {
        padding-right: -6.75rem !important
    }
    .pb-xl--7,
    .py-xl--7 {
        padding-bottom: -6.75rem !important
    }
    .pl-xl--7,
    .px-xl--7 {
        padding-left: -6.75rem !important
    }
    .p-xl--6 {
        padding: -4.5rem !important
    }
    .pt-xl--6,
    .py-xl--6 {
        padding-top: -4.5rem !important
    }
    .pr-xl--6,
    .px-xl--6 {
        padding-right: -4.5rem !important
    }
    .pb-xl--6,
    .py-xl--6 {
        padding-bottom: -4.5rem !important
    }
    .pl-xl--6,
    .px-xl--6 {
        padding-left: -4.5rem !important
    }
    .p-xl--5 {
        padding: -2.25rem !important
    }
    .pt-xl--5,
    .py-xl--5 {
        padding-top: -2.25rem !important
    }
    .pr-xl--5,
    .px-xl--5 {
        padding-right: -2.25rem !important
    }
    .pb-xl--5,
    .py-xl--5 {
        padding-bottom: -2.25rem !important
    }
    .pl-xl--5,
    .px-xl--5 {
        padding-left: -2.25rem !important
    }
    .p-xl--4 {
        padding: -1.5rem !important
    }
    .pt-xl--4,
    .py-xl--4 {
        padding-top: -1.5rem !important
    }
    .pr-xl--4,
    .px-xl--4 {
        padding-right: -1.5rem !important
    }
    .pb-xl--4,
    .py-xl--4 {
        padding-bottom: -1.5rem !important
    }
    .pl-xl--4,
    .px-xl--4 {
        padding-left: -1.5rem !important
    }
    .p-xl--3 {
        padding: -.75rem !important
    }
    .pt-xl--3,
    .py-xl--3 {
        padding-top: -.75rem !important
    }
    .pr-xl--3,
    .px-xl--3 {
        padding-right: -.75rem !important
    }
    .pb-xl--3,
    .py-xl--3 {
        padding-bottom: -.75rem !important
    }
    .pl-xl--3,
    .px-xl--3 {
        padding-left: -.75rem !important
    }
    .p-xl--2 {
        padding: -.375rem !important
    }
    .pt-xl--2,
    .py-xl--2 {
        padding-top: -.375rem !important
    }
    .pr-xl--2,
    .px-xl--2 {
        padding-right: -.375rem !important
    }
    .pb-xl--2,
    .py-xl--2 {
        padding-bottom: -.375rem !important
    }
    .pl-xl--2,
    .px-xl--2 {
        padding-left: -.375rem !important
    }
    .p-xl--1 {
        padding: -.1875rem !important
    }
    .pt-xl--1,
    .py-xl--1 {
        padding-top: -.1875rem !important
    }
    .pr-xl--1,
    .px-xl--1 {
        padding-right: -.1875rem !important
    }
    .pb-xl--1,
    .py-xl--1 {
        padding-bottom: -.1875rem !important
    }
    .pl-xl--1,
    .px-xl--1 {
        padding-left: -.1875rem !important
    }
    .p-xl-6 {
        padding: 4.5rem !important
    }
    .pt-xl-6,
    .py-xl-6 {
        padding-top: 4.5rem !important
    }
    .pr-xl-6,
    .px-xl-6 {
        padding-right: 4.5rem !important
    }
    .pb-xl-6,
    .py-xl-6 {
        padding-bottom: 4.5rem !important
    }
    .pl-xl-6,
    .px-xl-6 {
        padding-left: 4.5rem !important
    }
    .p-xl-7 {
        padding: 6.75rem !important
    }
    .pt-xl-7,
    .py-xl-7 {
        padding-top: 6.75rem !important
    }
    .pr-xl-7,
    .px-xl-7 {
        padding-right: 6.75rem !important
    }
    .pb-xl-7,
    .py-xl-7 {
        padding-bottom: 6.75rem !important
    }
    .pl-xl-7,
    .px-xl-7 {
        padding-left: 6.75rem !important
    }
    .p-xl-8 {
        padding: 13.5rem !important
    }
    .pt-xl-8,
    .py-xl-8 {
        padding-top: 13.5rem !important
    }
    .pr-xl-8,
    .px-xl-8 {
        padding-right: 13.5rem !important
    }
    .pb-xl-8,
    .py-xl-8 {
        padding-bottom: 13.5rem !important
    }
    .pl-xl-8,
    .px-xl-8 {
        padding-left: 13.5rem !important
    }
    .m-xl-auto {
        margin: auto !important
    }
    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important
    }
    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important
    }
    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important
    }
    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace
}

.text-justify {
    text-align: justify !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media (min-width:576px) {
    .text-sm-left {
        text-align: left !important
    }
    .text-sm-right {
        text-align: right !important
    }
    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width:768px) {
    .text-md-left {
        text-align: left !important
    }
    .text-md-right {
        text-align: right !important
    }
    .text-md-center {
        text-align: center !important
    }
}

@media (min-width:992px) {
    .text-lg-left {
        text-align: left !important
    }
    .text-lg-right {
        text-align: right !important
    }
    .text-lg-center {
        text-align: center !important
    }
}

@media (min-width:1200px) {
    .text-xl-left {
        text-align: left !important
    }
    .text-xl-right {
        text-align: right !important
    }
    .text-xl-center {
        text-align: center !important
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-light,
.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: 600 !important
}

.font-italic {
    font-style: italic !important
}

.text-primary {
    color: #2c7be5 !important
}

a.text-primary:focus,
a.text-primary:hover {
    color: #1862c6 !important
}

.text-secondary {
    color: #6e84a3 !important
}

a.text-secondary:focus,
a.text-secondary:hover {
    color: #566b88 !important
}

.text-success {
    color: #00d97e !important
}

a.text-success:focus,
a.text-success:hover {
    color: #00a660 !important
}

.text-info {
    color: #39afd1 !important
}

a.text-info:focus,
a.text-info:hover {
    color: #2991ae !important
}

.text-warning {
    color: #f6c343 !important
}

a.text-warning:focus,
a.text-warning:hover {
    color: #f4b312 !important
}

.text-danger {
    color: #e63757 !important
}

a.text-danger:focus,
a.text-danger:hover {
    color: #d01a3b !important
}

.text-light {
    color: #152e4d !important
}

a.text-light:focus,
a.text-light:hover {
    color: #0a1625 !important
}

.text-dark {
    color: #12263f !important
}

a.text-dark:focus,
a.text-dark:hover {
    color: #070e17 !important
}

.text-white {
    color: #fff !important
}

a.text-white:focus,
a.text-white:hover {
    color: #e6e6e6 !important
}

.text-body {
    color: #fff !important
}

.text-muted {
    color: #6e84a3 !important
}

.text-black-50 {
    color: rgba(18, 38, 63, .5) !important
}

.text-white-50 {
    color: hsla(0, 0%, 100%, .5) !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    border: 0;
    background-color: transparent;
    text-shadow: none
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media(min-width: 768px) {
    .d-md-flex {
        display: flex;
    }
}

.pointer-default {
    cursor: default
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.flatpickr-current-month .numInputWrapper span.arrowUp::after {
    border-bottom-color: rgba(255, 255, 255, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown::after {
    border-top-color: rgba(255, 255, 255, 0.9);
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
    background: inherit;
}