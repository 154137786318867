.rounded-circle {
    border-radius: 50% !important
}

.rounded {
    border-radius: .375rem !important
}

.card-avatar {
    display: block !important;
    margin-right: auto;
    margin-bottom: 1rem;
    margin-left: auto
}

.card-avatar-top {
    margin-top: -3rem
}

.card-avatar-top.avatar-xs {
    margin-top: -2.3125rem
}

.card-avatar-top.avatar-sm {
    margin-top: -2.75rem
}

.card-avatar-top.avatar-lg {
    margin-top: -3.5rem
}

.card-avatar-top.avatar-xl {
    margin-top: -4.0625rem
}

.card-avatar-top.avatar-xxl {
    margin-top: -5.5rem
}

.avatar {
    font-size: 1rem;
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 3rem
}

.avatar:after {
    position: absolute;
    width: 0;
    height: 0;
    content: "";
    background-image: url(../../assets/img/masks/avatar-status.svg), url(../../assets/img/masks/avatar-group.svg), url(../../assets/img/masks/avatar-group-hover.svg), url(../../assets/img/masks/avatar-group-hover-last.svg)
}

.avatar-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.avatar-title {
    display: flex;
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: #244166;
    align-items: center;
    justify-content: center
}

.avatar-offline:before,
.avatar-online:before {
    position: absolute;
    right: 5%;
    bottom: 5%;
    width: 20%;
    height: 20%;
    content: "";
    border-radius: 50%
}

.avatar-offline .avatar-img,
.avatar-online .avatar-img {
    -webkit-mask-image: url(../../assets/img/masks/avatar-status.svg);
    mask-image: url(../../assets/img/masks/avatar-status.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%
}

.avatar-online:before {
    background-color: #00d97e
}

.avatar-offline:before {
    background-color: #b1c2d9
}

.avatar-xs {
    font-size: .54167rem;
    width: 1.625rem;
    height: 1.625rem
}

.avatar-sm {
    font-size: .83333rem;
    width: 2.5rem;
    height: 2.5rem
}

.avatar-lg {
    font-size: 1.33333rem;
    width: 4rem;
    height: 4rem
}

.avatar-xl,
.avatar-xxl {
    font-size: 1.70833rem;
    width: 5.125rem;
    height: 5.125rem
}

@media (min-width:768px) {
    .avatar-xxl {
        font-size: 2.66667rem;
        width: 8rem;
        height: 8rem
    }
}

.avatar.avatar-4by3 {
    width: 4rem
}

.avatar-xs.avatar-4by3 {
    width: 2.16667rem
}

.avatar-sm.avatar-4by3 {
    width: 3.33333rem
}

.avatar-lg.avatar-4by3 {
    width: 5.33333rem
}

.avatar-xl.avatar-4by3 {
    width: 6.83333rem
}

.avatar-xxl.avatar-4by3 {
    width: 10.66667rem
}

.avatar-group {
    display: inline-flex
}

.avatar-group .avatar+.avatar {
    margin-left: -.75rem
}

.avatar-group .avatar-xs+.avatar-xs {
    margin-left: -.40625rem
}

.avatar-group .avatar-sm+.avatar-sm {
    margin-left: -.625rem
}

.avatar-group .avatar-lg+.avatar-lg {
    margin-left: -1rem
}

.avatar-group .avatar-xl+.avatar-xl {
    margin-left: -1.28125rem
}

.avatar-group .avatar-xxl+.avatar-xxl {
    margin-left: -2rem
}

.avatar-group .avatar:not(:last-child) {
    -webkit-mask-image: url(../../assets/img/masks/avatar-group.svg);
    mask-image: url(../../assets/img/masks/avatar-group.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%
}

.avatar-group .avatar:hover {
    z-index: 1;
    -webkit-mask-image: none;
    mask-image: none
}

.avatar-group .avatar:hover+.avatar {
    -webkit-mask-image: url(../../assets/img/masks/avatar-group-hover.svg);
    mask-image: url(../../assets/img/masks/avatar-group-hover.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%
}

.avatar-group .avatar:hover+.avatar:last-child {
    -webkit-mask-image: url(../../assets/img/masks/avatar-group-hover-last.svg);
    mask-image: url(../../assets/img/masks/avatar-group-hover-last.svg)
}