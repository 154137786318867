.example-enter {
    height: 0;
    overflow: hidden;
    transition: height .35s ease;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 2000ms ease-in;
}

.example-leave {
    /* opacity: 1; */
    /* max-height: 200px; */
}

.example-leave.example-leave-active {
    /* opacity: 0.01;
    transition: opacity .35s ease-in; */
    height: 0;
    transition: height .35s ease;
}

.example-appear {
    height: 0;
    /* max-height: 0; */
}

.example-appear.example-appear-active {
    height: auto;
    /* max-height: 200px; */
    overflow: hidden;
    transition: height .35s ease;
}

.nav-item {
    overflow: hidden;
}

.nav {
    display: flex;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    flex-wrap: wrap
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    color: #95aac9
}

.nav-tabs {
    border-bottom: 1px solid #1e3a5c
}

.nav-tabs .nav-item {
    margin-bottom: -1px
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: transparent
}

.nav-tabs .nav-link.disabled {
    color: #95aac9;
    border-color: transparent;
    background-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #fff;
    border-color: transparent transparent #2c7be5;
    background-color: transparent
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    border-radius: .375rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #2c7be5
}

.nav-fill .nav-item {
    text-align: center;
    flex: 1 1 auto
}

.nav-justified .nav-item {
    text-align: center;
    flex-basis: 0;
    flex-grow: 1
}

.navbar {
    position: relative;
    padding: .75rem 1rem
}

.navbar,
.navbar>.container,
.navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    font-size: 1.0625rem;
    line-height: inherit;
    display: inline-block;
    margin-right: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    font-size: 1.0625rem;
    line-height: 1;
    padding: .25rem 0;
    border: 1px solid transparent;
    border-radius: .375rem;
    background-color: transparent
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    content: "";
    vertical-align: middle;
    background: no-repeat 50%;
    background-size: 100% 100%
}

@media (max-width:575.98px) {
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }
    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media (max-width:767.98px) {
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }
    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media (max-width:991.98px) {
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }
    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media (max-width:1199.98px) {
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }
    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: #fff
}

.navbar-light .navbar-nav .nav-link {
    color: #6e84a3
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #fff
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(18, 38, 63, .3)
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-light .navbar-toggler {
    color: #6e84a3;
    border-color: transparent
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%236E84A3' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-light .navbar-text {
    color: #6e84a3
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover,
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: hsla(0, 0%, 100%, .7)
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: hsla(0, 0%, 100%, .25)
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: hsla(0, 0%, 100%, .7);
    border-color: transparent
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-dark .navbar-text {
    color: hsla(0, 0%, 100%, .7)
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff
}

.nav-tabs .nav-item {
    margin-right: .75rem;
    margin-left: .75rem
}

.nav-tabs .nav-link {
    padding: 1rem 0;
    border-top-width: 0;
    border-right-width: 0;
    border-bottom: 1px solid transparent;
    border-left-width: 0
}

.nav-tabs .nav-link:not(.active) {
    color: #95aac9
}

.nav-tabs .nav-link:not(.active):hover {
    color: #6e84a3
}

.nav-tabs .nav-item:first-child {
    margin-left: 0
}

.nav-tabs .nav-item:last-child {
    margin-right: 0
}

.nav-tabs .nav-item.show .nav-link {
    border-color: transparent
}

.nav-overflow {
    display: flex;
    overflow-x: auto;
    padding-bottom: 1px;
    flex-wrap: nowrap
}

.nav-overflow::-webkit-scrollbar {
    display: none
}

.nav-tabs-sm {
    font-size: .8125rem
}

.nav-tabs-sm .nav-item {
    margin-right: .5rem;
    margin-left: .5rem
}

.nav-tabs-sm .nav-link {
    padding-top: 1.15385rem;
    padding-bottom: 1.15385rem
}

.nav-sm .nav-link {
    font-size: .8125rem
}

.nav.btn-group .btn-white.active {
    color: #fff;
    border-color: #2c7be5;
    background-color: #2c7be5
}

.navbar {
    border-width: 0 0 1px;
    border-style: solid
}

.navbar-dark {
    border: none;
    border-color: hsla(0, 0%, 100%, .2);
    background-color: #12263f
}

.navbar-dark .navbar-heading {
    color: hsla(0, 0%, 100%, .4)
}

.navbar-dark .navbar-divider {
    border-color: hsla(0, 0%, 100%, .2)
}

.navbar-dark .navbar-user {
    border-top-color: hsla(0, 0%, 100%, .2)
}

.navbar-dark .navbar-user-link {
    color: hsla(0, 0%, 100%, .7)
}

.navbar-dark .navbar-user-link:focus,
.navbar-dark .navbar-user-link:hover {
    color: #fff
}

.navbar-dark .navbar-brand {
    filter: brightness(0) invert(1)
}

.navbar-dark .navbar-collapse:before {
    border-top-color: hsla(0, 0%, 100%, .2)
}

.navbar-light {
    border-color: #12263f;
    background-color: #152e4d
}

.navbar-light .navbar-heading {
    color: #6e84a3
}

.navbar-light .navbar-divider {
    border-color: #1e3a5c
}

.navbar-light .navbar-user {
    border-top-color: #1e3a5c
}

.navbar-light .navbar-user-link {
    color: #6e84a3
}

.navbar-light .navbar-user-link:focus,
.navbar-light .navbar-user-link:hover {
    color: #fff
}

.navbar-light .navbar-brand {
    filter: none
}

.navbar-light .navbar-collapse:before {
    border-top-color: #1e3a5c
}

.navbar-brand {
    margin-right: 0
}

.navbar-brand-img,
.navbar-brand>img {
    max-width: 100%;
    max-height: 1.5rem
}

.navbar-collapse {
    margin-right: -1rem;
    margin-left: -1rem;
    padding-right: 1rem;
    padding-left: 1rem
}

.navbar-collapse:before {
    display: block;
    margin: .75rem -1rem;
    content: "";
    border-top-width: 1px;
    border-top-style: solid
}

.navbar-nav {
    margin-right: -1rem;
    margin-left: -1rem
}

.navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem
}

.navbar-nav .nav-link.active {
    position: relative
}

.navbar-nav .nav-link.active:before {
    position: absolute;
    top: .5rem;
    bottom: .5rem;
    left: 0;
    content: "";
    border-left: 2px solid #2c7be5
}

.navbar-nav .nav-link>.fe {
    font-size: 1.0625rem;
    line-height: 1.40625rem;
    padding-bottom: .125em
}

.navbar-nav .dropdown-menu {
    border: none
}

.navbar-nav .dropdown-menu .dropdown-menu {
    margin-left: .75rem
}

.navbar-expand-xs,
.navbar-expand-xs .navbar>[class*=container] {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand-xs .navbar-collapse {
    flex-grow: inherit
}

.navbar-expand-xs .navbar-collapse:before {
    display: none
}

.navbar-expand-xs .navbar-nav {
    margin-right: 0;
    margin-left: 0
}

.navbar-expand-xs .navbar-nav .nav-link {
    padding: .625rem .5rem
}

.navbar-expand-xs .navbar-nav .nav-link.active {
    position: relative
}

.navbar-expand-xs .navbar-nav .nav-link.active:before {
    top: auto;
    right: .5rem;
    bottom: calc(-.75rem - 1px);
    left: .5rem;
    border-bottom: 1px solid #2c7be5;
    border-left: 0
}

.navbar-expand-xs .navbar-nav .dropdown-menu {
    border: 1px solid #12263f
}

.navbar-expand-xs .navbar-nav .dropdown-menu .dropdown-menu {
    margin-left: 0
}

@media (min-width:576px) {
    .navbar-expand-sm,
    .navbar-expand-sm .navbar>[class*=container] {
        padding-right: 0;
        padding-left: 0
    }
    .navbar-expand-sm .navbar-collapse {
        flex-grow: inherit
    }
    .navbar-expand-sm .navbar-collapse:before {
        display: none
    }
    .navbar-expand-sm .navbar-nav {
        margin-right: 0;
        margin-left: 0
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding: .625rem .5rem
    }
    .navbar-expand-sm .navbar-nav .nav-link.active {
        position: relative
    }
    .navbar-expand-sm .navbar-nav .nav-link.active:before {
        top: auto;
        right: .5rem;
        bottom: calc(-.75rem - 1px);
        left: .5rem;
        border-bottom: 1px solid #2c7be5;
        border-left: 0
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        border: 1px solid #12263f
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu .dropdown-menu {
        margin-left: 0
    }
}

@media (min-width:768px) {
    .navbar-expand-md,
    .navbar-expand-md .navbar>[class*=container] {
        padding-right: 0;
        padding-left: 0
    }
    .navbar-expand-md .navbar-collapse {
        flex-grow: inherit
    }
    .navbar-expand-md .navbar-collapse:before {
        display: none
    }
    .navbar-expand-md .navbar-nav {
        margin-right: 0;
        margin-left: 0
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding: .625rem .5rem
    }
    .navbar-expand-md .navbar-nav .nav-link.active {
        position: relative
    }
    .navbar-expand-md .navbar-nav .nav-link.active:before {
        top: auto;
        right: .5rem;
        bottom: calc(-.75rem - 1px);
        left: .5rem;
        border-bottom: 1px solid #2c7be5;
        border-left: 0
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        border: 1px solid #12263f
    }
    .navbar-expand-md .navbar-nav .dropdown-menu .dropdown-menu {
        margin-left: 0
    }
}

@media (min-width:992px) {
    .navbar-expand-lg,
    .navbar-expand-lg .navbar>[class*=container] {
        padding-right: 0;
        padding-left: 0
    }
    .navbar-expand-lg .navbar-collapse {
        flex-grow: inherit
    }
    .navbar-expand-lg .navbar-collapse:before {
        display: none
    }
    .navbar-expand-lg .navbar-nav {
        margin-right: 0;
        margin-left: 0
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding: .625rem .5rem
    }
    .navbar-expand-lg .navbar-nav .nav-link.active {
        position: relative
    }
    .navbar-expand-lg .navbar-nav .nav-link.active:before {
        top: auto;
        right: .5rem;
        bottom: calc(-.75rem - 1px);
        left: .5rem;
        border-bottom: 1px solid #2c7be5;
        border-left: 0
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        border: 1px solid #12263f
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-menu {
        margin-left: 0
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl,
    .navbar-expand-xl .navbar>[class*=container] {
        padding-right: 0;
        padding-left: 0
    }
    .navbar-expand-xl .navbar-collapse {
        flex-grow: inherit
    }
    .navbar-expand-xl .navbar-collapse:before {
        display: none
    }
    .navbar-expand-xl .navbar-nav {
        margin-right: 0;
        margin-left: 0
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding: .625rem .5rem
    }
    .navbar-expand-xl .navbar-nav .nav-link.active {
        position: relative
    }
    .navbar-expand-xl .navbar-nav .nav-link.active:before {
        top: auto;
        right: .5rem;
        bottom: calc(-.75rem - 1px);
        left: .5rem;
        border-bottom: 1px solid #2c7be5;
        border-left: 0
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        border: 1px solid #12263f
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu .dropdown-menu {
        margin-left: 0
    }
}

.navbar[class*=fixed-] {
    z-index: 1030
}

.navbar-overflow {
    display: flex;
    overflow-x: auto;
    margin-bottom: -.75rem;
    flex-grow: 1;
    flex-basis: 0
}

.navbar-overflow::-webkit-scrollbar {
    display: none
}

.navbar-overflow .navbar-nav {
    flex-direction: row;
    margin-right: -.5rem;
    margin-left: -.5rem;
    flex-wrap: nowrap
}

.navbar-overflow .navbar-nav .nav-link {
    padding-bottom: 1.25rem
}

.navbar-overflow .navbar-nav .nav-link.active:before {
    bottom: 0
}

.navbar-breadcrumb {
    padding: .5rem 0
}

.navbar-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around
}

.navbar-user .dropdown-toggle:after {
    display: none
}

.navbar-nav .nav-link>.fe {
    display: inline-block;
    min-width: 1.75rem
}

.navbar-vertical .navbar-nav .nav-link {
    display: flex;
    align-items: center
}

.navbar-vertical .navbar-nav .nav-link[data-toggle=collapse]:after {
    font-family: Feather;
    display: block;
    margin-left: auto;
    content: "\e92e";
    transition: transform .2s
}

.navbar-vertical .navbar-nav .nav-link[data-toggle=collapse][aria-expanded=true]:after {
    transform: rotate(-180deg)
}

.navbar-vertical .navbar-nav .nav .nav-link {
    padding-left: 2.75rem
}

.navbar-vertical .navbar-nav .nav .nav .nav-link {
    padding-left: 3.25rem
}

.navbar-vertical .navbar-heading {
    font-size: .625rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    letter-spacing: .04em;
    text-transform: uppercase
}

.navbar-vertical.navbar-expand-xs {
    position: fixed;
    top: 0;
    bottom: 0;
    display: block;
    overflow-y: auto;
    width: 100%;
    max-width: 250px;
    padding-right: 1.5rem;
    padding-left: 1.5rem
}

.navbar-vertical.navbar-expand-xs>[class*=container] {
    flex-direction: column;
    min-height: 100%;
    padding-right: 0;
    padding-left: 0;
    align-items: stretch
}

@media (-ms-high-contrast:active),
(-ms-high-contrast:none) {
    .navbar-vertical.navbar-expand-xs>[class*=container] {
        height: 100%;
        min-height: none
    }
}

.navbar-vertical.navbar-expand-xs.fixed-left {
    left: 0;
    border-width: 0 1px 0 0
}

.navbar-vertical.navbar-expand-xs.fixed-right {
    right: 0;
    border-width: 0 0 0 1px
}

.navbar-vertical.navbar-expand-xs .navbar-collapse {
    display: flex;
    flex-direction: column;
    margin-right: -1.5rem;
    margin-left: -1.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    flex: 1 1;
    align-items: stretch
}

.navbar-vertical.navbar-expand-xs .navbar-collapse>* {
    min-width: 100%
}

.navbar-vertical.navbar-expand-xs .navbar-nav {
    flex-direction: column;
    margin-right: -1.5rem;
    margin-left: -1.5rem
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
    padding: .5rem 1.5rem
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link.active:before {
    top: .5rem;
    right: auto;
    bottom: .5rem;
    left: 0;
    border-bottom: 0;
    border-left: 2px solid #2c7be5
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav-link {
    padding-left: 3.25rem
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav .nav-link {
    padding-left: 4rem
}

.navbar-vertical.navbar-expand-xs .navbar-brand {
    display: block;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    text-align: center
}

.navbar-vertical.navbar-expand-xs .navbar-brand-img {
    max-height: 3rem
}

.navbar-vertical.navbar-expand-xs .navbar-user {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
    padding: 1.5rem 1.5rem .75rem;
    border-top-width: 1px;
    border-top-style: solid
}

.navbar-vertical.navbar-expand-xs .navbar-user .dropup .dropdown-menu {
    left: 50%;
    transform: translateX(-50%)
}

@media (min-width:576px) {
    .navbar-vertical.navbar-expand-sm {
        position: fixed;
        top: 0;
        bottom: 0;
        display: block;
        overflow-y: auto;
        width: 100%;
        max-width: 250px;
        padding-right: 1.5rem;
        padding-left: 1.5rem
    }
    .navbar-vertical.navbar-expand-sm>[class*=container] {
        flex-direction: column;
        min-height: 100%;
        padding-right: 0;
        padding-left: 0;
        align-items: stretch
    }
}

@media (min-width:576px) and (-ms-high-contrast:active),
(min-width:576px) and (-ms-high-contrast:none) {
    .navbar-vertical.navbar-expand-sm>[class*=container] {
        height: 100%;
        min-height: none
    }
}

@media (min-width:576px) {
    .navbar-vertical.navbar-expand-sm.fixed-left {
        left: 0;
        border-width: 0 1px 0 0
    }
    .navbar-vertical.navbar-expand-sm.fixed-right {
        right: 0;
        border-width: 0 0 0 1px
    }
    .navbar-vertical.navbar-expand-sm .navbar-collapse {
        display: flex;
        flex-direction: column;
        margin-right: -1.5rem;
        margin-left: -1.5rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        flex: 1 1;
        align-items: stretch
    }
    .navbar-vertical.navbar-expand-sm .navbar-collapse>* {
        min-width: 100%
    }
    .navbar-vertical.navbar-expand-sm .navbar-nav {
        flex-direction: column;
        margin-right: -1.5rem;
        margin-left: -1.5rem
    }
    .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link {
        padding: .5rem 1.5rem
    }
    .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link.active:before {
        top: .5rem;
        right: auto;
        bottom: .5rem;
        left: 0;
        border-bottom: 0;
        border-left: 2px solid #2c7be5
    }
    .navbar-vertical.navbar-expand-sm .navbar-nav .nav .nav-link {
        padding-left: 3.25rem
    }
    .navbar-vertical.navbar-expand-sm .navbar-nav .nav .nav .nav-link {
        padding-left: 4rem
    }
    .navbar-vertical.navbar-expand-sm .navbar-brand {
        display: block;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        text-align: center
    }
    .navbar-vertical.navbar-expand-sm .navbar-brand-img {
        max-height: 3rem
    }
    .navbar-vertical.navbar-expand-sm .navbar-user {
        margin-right: -1.5rem;
        margin-left: -1.5rem;
        padding: 1.5rem 1.5rem .75rem;
        border-top-width: 1px;
        border-top-style: solid
    }
    .navbar-vertical.navbar-expand-sm .navbar-user .dropup .dropdown-menu {
        left: 50%;
        transform: translateX(-50%)
    }
}

@media (min-width:768px) {
    .navbar-vertical.navbar-expand-md {
        position: fixed;
        top: 0;
        bottom: 0;
        display: block;
        overflow-y: auto;
        width: 100%;
        max-width: 250px;
        padding-right: 1.5rem;
        padding-left: 1.5rem
    }
    .navbar-vertical.navbar-expand-md>[class*=container] {
        flex-direction: column;
        min-height: 100%;
        padding-right: 0;
        padding-left: 0;
        align-items: stretch
    }
}

@media (min-width:768px) and (-ms-high-contrast:active),
(min-width:768px) and (-ms-high-contrast:none) {
    .navbar-vertical.navbar-expand-md>[class*=container] {
        height: 100%;
        min-height: none
    }
}

@media (min-width:768px) {
    .navbar-vertical.navbar-expand-md.fixed-left {
        left: 0;
        border-width: 0 1px 0 0
    }
    .navbar-vertical.navbar-expand-md.fixed-right {
        right: 0;
        border-width: 0 0 0 1px
    }
    .navbar-vertical.navbar-expand-md .navbar-collapse {
        display: flex;
        flex-direction: column;
        margin-right: -1.5rem;
        margin-left: -1.5rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        flex: 1 1;
        align-items: stretch
    }
    .navbar-vertical.navbar-expand-md .navbar-collapse>* {
        min-width: 100%
    }
    .navbar-vertical.navbar-expand-md .navbar-nav {
        flex-direction: column;
        margin-right: -1.5rem;
        margin-left: -1.5rem
    }
    .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
        padding: .5rem 1.5rem
    }
    .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
        top: .5rem;
        right: auto;
        bottom: .5rem;
        left: 0;
        border-bottom: 0;
        border-left: 2px solid #2c7be5
    }
    .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav-link {
        padding-left: 3.25rem
    }
    .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav .nav-link {
        padding-left: 4rem
    }
    .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav .nav .nav-link {
        padding-left: 4.75rem
    }
    .navbar-vertical.navbar-expand-md .navbar-brand {
        display: block;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        text-align: center
    }
    .navbar-vertical.navbar-expand-md .navbar-brand-img {
        max-height: 3rem
    }
    .navbar-vertical.navbar-expand-md .navbar-user {
        margin-right: -1.5rem;
        margin-left: -1.5rem;
        padding: 1.5rem 1.5rem .75rem;
        border-top-width: 1px;
        border-top-style: solid
    }
    .navbar-vertical.navbar-expand-md .navbar-user .dropup .dropdown-menu {
        left: 50%;
        transform: translateX(-50%)
    }
}

@media (min-width:992px) {
    .navbar-vertical.navbar-expand-lg {
        position: fixed;
        top: 0;
        bottom: 0;
        display: block;
        overflow-y: auto;
        width: 100%;
        max-width: 250px;
        padding-right: 1.5rem;
        padding-left: 1.5rem
    }
    .navbar-vertical.navbar-expand-lg>[class*=container] {
        flex-direction: column;
        min-height: 100%;
        padding-right: 0;
        padding-left: 0;
        align-items: stretch
    }
}

@media (min-width:992px) and (-ms-high-contrast:active),
(min-width:992px) and (-ms-high-contrast:none) {
    .navbar-vertical.navbar-expand-lg>[class*=container] {
        height: 100%;
        min-height: none
    }
}

@media (min-width:992px) {
    .navbar-vertical.navbar-expand-lg.fixed-left {
        left: 0;
        border-width: 0 1px 0 0
    }
    .navbar-vertical.navbar-expand-lg.fixed-right {
        right: 0;
        border-width: 0 0 0 1px
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse {
        display: flex;
        flex-direction: column;
        margin-right: -1.5rem;
        margin-left: -1.5rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        flex: 1 1;
        align-items: stretch
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse>* {
        min-width: 100%
    }
    .navbar-vertical.navbar-expand-lg .navbar-nav {
        flex-direction: column;
        margin-right: -1.5rem;
        margin-left: -1.5rem
    }
    .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
        padding: .5rem 1.5rem
    }
    .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.active:before {
        top: .5rem;
        right: auto;
        bottom: .5rem;
        left: 0;
        border-bottom: 0;
        border-left: 2px solid #2c7be5
    }
    .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-link {
        padding-left: 3.25rem
    }
    .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav .nav-link {
        padding-left: 4rem
    }
    .navbar-vertical.navbar-expand-lg .navbar-brand {
        display: block;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        text-align: center
    }
    .navbar-vertical.navbar-expand-lg .navbar-brand-img {
        max-height: 3rem
    }
    .navbar-vertical.navbar-expand-lg .navbar-user {
        margin-right: -1.5rem;
        margin-left: -1.5rem;
        padding: 1.5rem 1.5rem .75rem;
        border-top-width: 1px;
        border-top-style: solid
    }
    .navbar-vertical.navbar-expand-lg .navbar-user .dropup .dropdown-menu {
        left: 50%;
        transform: translateX(-50%)
    }
}

@media (min-width:1200px) {
    .navbar-vertical.navbar-expand-xl {
        position: fixed;
        top: 0;
        bottom: 0;
        display: block;
        overflow-y: auto;
        width: 100%;
        max-width: 250px;
        padding-right: 1.5rem;
        padding-left: 1.5rem
    }
    .navbar-vertical.navbar-expand-xl>[class*=container] {
        flex-direction: column;
        min-height: 100%;
        padding-right: 0;
        padding-left: 0;
        align-items: stretch
    }
}

@media (min-width:1200px) and (-ms-high-contrast:active),
(min-width:1200px) and (-ms-high-contrast:none) {
    .navbar-vertical.navbar-expand-xl>[class*=container] {
        height: 100%;
        min-height: none
    }
}

@media (min-width:1200px) {
    .navbar-vertical.navbar-expand-xl.fixed-left {
        left: 0;
        border-width: 0 1px 0 0
    }
    .navbar-vertical.navbar-expand-xl.fixed-right {
        right: 0;
        border-width: 0 0 0 1px
    }
    .navbar-vertical.navbar-expand-xl .navbar-collapse {
        display: flex;
        flex-direction: column;
        margin-right: -1.5rem;
        margin-left: -1.5rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        flex: 1 1;
        align-items: stretch
    }
    .navbar-vertical.navbar-expand-xl .navbar-collapse>* {
        min-width: 100%
    }
    .navbar-vertical.navbar-expand-xl .navbar-nav {
        flex-direction: column;
        margin-right: -1.5rem;
        margin-left: -1.5rem
    }
    .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link {
        padding: .5rem 1.5rem
    }
    .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link.active:before {
        top: .5rem;
        right: auto;
        bottom: .5rem;
        left: 0;
        border-bottom: 0;
        border-left: 2px solid #2c7be5
    }
    .navbar-vertical.navbar-expand-xl .navbar-nav .nav .nav-link {
        padding-left: 3.25rem
    }
    .navbar-vertical.navbar-expand-xl .navbar-nav .nav .nav .nav-link {
        padding-left: 4rem
    }
    .navbar-vertical.navbar-expand-xl .navbar-brand {
        display: block;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        text-align: center
    }
    .navbar-vertical.navbar-expand-xl .navbar-brand-img {
        max-height: 3rem
    }
    .navbar-vertical.navbar-expand-xl .navbar-user {
        margin-right: -1.5rem;
        margin-left: -1.5rem;
        padding: 1.5rem 1.5rem .75rem;
        border-top-width: 1px;
        border-top-style: solid
    }
    .navbar-vertical.navbar-expand-xl .navbar-user .dropup .dropdown-menu {
        left: 50%;
        transform: translateX(-50%)
    }
}