select {
    -webkit-appearance: none;
}

.w-10 {
    width: 10% !important;
}

.w-5 {
    width: 5% !important;
}